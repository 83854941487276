// import { useRoutes,useSearchParams,useParams,useLocation } from "react-router-dom";
// const queryParams = useLocation()


export const env_variables = {
  apiurl: 'https://krtiuat-online-api.sayshop.co', //QA
  brandurl: 'https://sayshop-admin.theecentral.com',
  //apiurl: 'http://localhost:7200', //LOCAL
  //http://api01.sayshop.co
  SecretKey: 'SF@Kr&Ar:):)%1610',
}


// apiurl: 'http://api01.sayshop.co', //QA