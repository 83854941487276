import React, { useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import env from "../../enviroinment";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import AddIcon from "../../assets/images/addIcon.svg";
import AdminNav from './AdminNav'
import AdminHeader from './AdminHeader';
import CloseIcon from '../../assets/images/close_icon.svg';
// import BootstrapTable from 'react-bootstrap-table-next';
// import 'bootstrap/dist/css/bootstrap.min.css';
import EditIcon from "../../assets/images/Edit_icon.svg";
import DeleteIcon from "../../assets/images/delete_icon.svg";
import NodataImage from "../../assets/images/noData.png";
import Pagination from "../Pagination";
import { useLocation, useParams } from "react-router-dom";
import LoadingSpinner from "../loadingSpinner";

const SubCategory = () => {

  let params = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  let token = localStorage.getItem("token");
  let userId = localStorage.getItem("userId");
  let [getCat, setGetCat] = useState([]);
  const [query, setQuery] = useState("");
  const [postsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  let [getsub, setGetSub] = useState([]);

  const [showcat, setShowcat] = useState(false);
  const [showsub, setShowsub] = useState(false);
  const [category, setCategory] = useState([]);


  const [columnNum, setColumnNum] = useState(0);
  let columnNumInc = columnNum;

  // table function






  useEffect(() => {
    // console.log("idididid",params.id);
    handleGetSubCat(params.id)

  }, [])
  //posting the category

  //formik for category



  //posting the subcategory
  let handlesubcategory = async (values) => {
    try {
      console.log(values)
      let res = await axios.post(`${env.apiurl}/foods/add-subcategory`, values, {
        headers: { Authorization: `Bearer ${token} `, adminid: userId },
      });
      console.log(res.data)
      if (res.data.statusCode === 200) {

        toast.success(res.data.message);
        setShowsub(false);
      } else {
        toast.error(res.data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const formSubCategory = useFormik({
    initialValues: {
      subcategory: "",
      categoryId: ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      subcategory: Yup.string().required("Subcategory is required"),
      categoryId: Yup.string().required('Please select the category')
    }),
    onSubmit: (values) => {
      handlesubcategory(values)
    }


  })

  // table

  // search
  let searchQuery = getsub.filter((products) =>
    products.subcategory.toString().toLowerCase().includes(query.toLowerCase())
  );
  searchQuery = searchQuery.slice(indexOfFirstPost, indexOfLastPost);

  // pagination
  const paginate = (pageNumber) => {
    setColumnNum((pageNumber - 1) * 10);
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const handleQueryinput = (event) => {
    if (location.pathname === "/dashboard") {
      setQuery(event.target.value);
    }
  };

  // delete category functio

  let handleDelete = async (id) => {
    try {
      setLoading(true);
      let res = await axios.delete(`${env.apiurl}/foods/delete-subcategory/${id}`, {
        headers: { Authorization: `Bearer ${token}`, adminid: userId },
      });
      setLoading(false);
      if (res.data.statusCode === 200) {
        toast.error("SubCategory Deleted", {
          position: toast.POSITION.TOP_RIGHT,


        });
        handleGetSubCat()

      }
    } catch (error) {
      setLoading(false);
    }
  };

  //getting subcategory
  const handleGetSubCat = async (id) => {


    try {
      let res = await axios.get(
        `${env.apiurl}/foods/get-subcategorytable/${params.id}`,
        {
          headers: { Authorization: `Bearer ${token} `, adminid: userId },
        }
      );
      console.log(res.data);
      if (res.data.statusCode === 200) {
        setGetSub(res.data.data);
      } else {
        setGetSub([]);
      }
    } catch (error) {
      console.log(error);
    }
  };









  return (
    <div>
      {loading ? <LoadingSpinner /> : null}
      <div className="Main_header">
        <AdminHeader />
        <AdminNav />
      </div>


      <div className="pageInfo">
        <div>
          <h2 className="heading24_bold">SubCategory Settings</h2>
        </div>

        <div>
          {/* <button
                  className="commonButton RoyalFlycatcherCrestColor"
                  onClick={()=>{setShowsub(true);}}
                >
                  <img className="mr_8" src={AddIcon} /> <span>Add Sub-Category</span>
                </button> */}

        </div>

      </div>

      <div className="TableWrapper tableResponsive">
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th>SubCategory</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {searchQuery.length > 0
              ? searchQuery.map((product, index) => {
                columnNumInc = columnNumInc + 1;
                return (
                  <tr key={index}>
                    <td>{columnNumInc}</td>
                    <td >{product.subcategory} </td>
                    <td>
                      <div className="displayFlex alignItem_Center">
                        <div className="mr_12">
                          {/* <button
                                    className="Edit_btn"
                                   
                                  >
                                    <img src={EditIcon} />
                                  </button> */}
                        </div>
                        <div>
                          <button
                            className="Delete_btn"
                            onClick={() => handleDelete(product._id)}
                          >

                            <img src={DeleteIcon} />
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
              :
              <tr>
                <td colspan="6" className="textAlign_center">
                  No SubCategory
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={getCat.length}
        paginate={paginate}
      />











      <ToastContainer autoClose={1800} />




      <Modal show={showsub} className="addnewpopup">
        {/* onHide={handleClose}  */}
        <Modal.Header>
          <div>
            <h4>Add Sub-Category</h4>
          </div>
          <div>
            <button className="emptyBtn" onClick={() => { setShowsub(false); }}>

              <img src={CloseIcon} />
            </button>
          </div>
        </Modal.Header>
        <div className="modelScroll">
          <Modal.Body>
            <form onSubmit={formSubCategory.handleSubmit}>
              <div className="mb_16">
                <label className="inputTitle mb_8">Category</label>
                <select
                  onChange={formSubCategory.handleChange}
                  className="MainInput"
                  name="categoryId"
                  required
                >
                  <option value="" disabled selected>Select Category</option>{
                    getCat?.map((e, i) => {
                      return <option value={e._id} key={i}>{e.categoryName}</option>
                    })
                  }

                </select>

                <input
                  type="text"
                  onBlur={formSubCategory.handleBlur}
                  id="subcategory"
                  name="subcategory"
                  placeholder="Enter SubCategory"
                  className="MainInput"
                  onChange={formSubCategory.handleChange}
                  maxLength={45}
                  minLength={3}

                />



                {formSubCategory.touched.categoryId && formSubCategory.errors.categoryId ? (
                  <p className="error_text">{formSubCategory.errors.categoryId}</p>
                ) : null}
              </div>
              <div className="row mb_16">
                <div className="mobSpace_mb16 col-md-6">
                  <label className="mb_8 inputTitle">Subcategory</label>
                  <input
                    type="text"
                    onBlur={formSubCategory.handleBlur}
                    id="subcategory"
                    name="subcategory"
                    placeholder="Enter SubCategory"
                    className="MainInput"
                    onChange={formSubCategory.handleChange}
                    maxLength={45}
                    minLength={3}

                  />
                  {formSubCategory.touched.subcategory && formSubCategory.errors.subcategory ? (
                    <p className="error_text">{formSubCategory.errors.subcategory}</p>
                  ) : null}
                </div>


                <button
                  className="MainButton margin_auto width_160 mb_16"
                  type="submit"
                >
                  Add
                </button>
              </div>
            </form>

          </Modal.Body>
        </div>
      </Modal>



    </div>
  )
}

export default SubCategory;