import React, { useState, useEffect } from "react";
import AdminNav from "./AdminNav";
import AdminHeader from "./AdminHeader";
import axios from "axios";
import env from "../../enviroinment";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import EditIcon from "../../assets/images/Edit_icon.svg";
import DeleteIcon from "../../assets/images/delete_icon.svg";
import CloseIcon from "../../assets/images/close_icon.svg";
import UploadIcon from "../../assets/images/upload_icon.svg";
import UploadimagCloseIcon from "../../assets/images/uploadImg_closeIcon.svg";
import NodataImage from "../../assets/images/noData.png";
import Pagination from "../Pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BeveragesIcon from "../../assets/images/beverages_icon.svg";
import MojitoIcon from "../../assets/images/mojito_icon.svg";
import juicesIcon from "../../assets/images/juices_icon.svg";
import SnacksIcon from "../../assets/images/snacks_icon.svg";
import SandwichIcon from "../../assets/images/sandwich_icon.svg";
import IcecreamIcon from "../../assets/images/icecream_icon.svg";
import PizzaIcon from "../../assets/images/pizza_icon.svg";
import FaloodaIcon from "../../assets/images/falooda_icon.svg";
import BurgerIcon from "../../assets/images/burger_icon.svg";
import vegIcon from "../../assets/images/veg_icon.svg";
import NonvegIcon from "../../assets/images/nonveg_icon.svg";
import { useLocation } from "react-router-dom";
import AddIcon from "../../assets/images/addIcon.svg";
import LoadingSpinner from "../loadingSpinner";

const ProductDashboard = () => {
  // New Reactbootstrap modal start
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setFile(undefined);
  };
  const handleShow = () => setShow(true);
  let [getCat, setGetCat] = useState([]);
  let [getsub, setGetSub] = useState([]);

  //modal for edit
  const [showedit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const [query, setQuery] = useState("");
  //pagination states
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const location = useLocation();
  //getting the category
  let symbol = localStorage.getItem("symbol");
  let handleGetCategory = async () => {
    try {
      let res = await axios.get(`${env.apiurl}/foods/get-category`, {
        headers: { Authorization: `Bearer ${token} `, adminid: userId },
      });
      if (res.data.statusCode === 200) {
        setGetCat(res.data.data);
        console.log("====>find", res.data.data);
      } else {
        // toast.error(res.data.message);
        console.log(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //getting subcategory
  const handleGetSubCat = async (e) => {
    setInputValues({
      ...inputValues,
      category: e.target.value,
      subCategory: "",
    });

    try {
      let res = await axios.get(
        `${env.apiurl}/foods/get-subcategory/${e.target.value}`,
        {
          headers: { Authorization: `Bearer ${token} `, adminid: userId },
        }
      );
      console.log(res.data);
      if (res.data.statusCode === 200) {
        setGetSub(res.data.data);
      } else {
        setGetSub([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const EditGetSubCat = async (e) => {
    try {
      let res = await axios.get(`${env.apiurl}/foods/get-subcategory/${e}`, {
        headers: { Authorization: `Bearer ${token} `, adminid: userId },
      });
      console.log(res.data);
      if (res.data.statusCode === 200) {
        setGetSub(res.data.data);
        console.log(getsub);
      } else {
        setGetSub([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetCategory();
  }, []);

  const handleShowEdit = (e, product) => {
    localStorage.setItem("SelectedProductId", product._id);
    setPreview(product.image);
    setSelectedFood(product);
    setInputValues({
      name: product.name,
      price: product.price,
      quantity: product.quantity,
      deliveryFee: product.deliveryFee,
      description: product.description,
      category: product.category,
      subCategory: product.subCategory,
      vegorNonveg: product.vegorNonveg,
    });
    if (product.subCategory) {
      EditGetSubCat(product.category);
    }

    console.log("intialvalue", inputValues);

    setShowEdit(true);
    return;
  };
  // New Reactbootstrap modal End
  const [modalIsOpen, setModalIsOpen] = useState(false);

  let navigate = useNavigate();
  let token = localStorage.getItem("token");
  let userId = localStorage.getItem("userId");
  let [data, setData] = useState([]);
  let [file, setFile] = useState();
  let [selectedFood, setSelectedFood] = useState({});
  const [preview, setPreview] = useState();
  const [columnNum, setColumnNum] = useState(0);
  let columnNumInc = columnNum;
  let [inputValues, setInputValues] = useState({
    name: "",
    price: "",
    // price: 0,
    quantity: "",
    description: "",
    deliveryFee: "",
    category: "",
    subCategory: "",
    vegorNonveg: "",
  });
  console.log("testtttttttttttttttttttt", inputValues);
  let [errors, setErrors] = useState({});

  let handleDelete = async (id) => {
    try {
      setLoading(true);
      let res = await axios.delete(`${env.apiurl}/foods/delete-food/${id}`, {
        headers: { Authorization: `Bearer ${token}`, adminid: userId },
      });
      setLoading(false);
      if (res.data.statusCode === 200) {
        toast.error("Product Deleted", {
          position: toast.POSITION.TOP_RIGHT,
        });
        loadData();
      }
    } catch (error) {
      setLoading(false);
    }
  };
  let loadData = async () => {
    try {
      setLoading(true);
      let res = await axios.get(`${env.apiurl}/foods/all-food`, {
        headers: { Authorization: `Bearer ${token}`, adminid: userId },
      });
      console.log(res.data.food);
      if (res.data.statusCode === 200) {
        setData(res.data.food);
        setInputValues({
          name: "",
          price: "",
          // price: 0,
          quantity: "",
          description: "",
          deliveryFee: "",
          category: "",
          subCategory: "",
          vegorNonveg: "",
        });
        // console.log("=====>", res.data.food)
      }
      // window.location.reload();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleFile = (e) => {
    e.preventDefault();
    let selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const input_name = e.target.name;
    const input_value = e.target.value;

    if (
      input_name === "quantity" ||
      input_name === "price" ||
      input_name === "deliveryFee"
    ) {
      if (input_value >= 0) {
        setInputValues({ ...inputValues, [input_name]: input_value });
      } else {
        setInputValues({ ...inputValues, [input_name]: 0 });
      }
    } else {
      setInputValues({ ...inputValues, [input_name]: input_value });
    }
    console.log("intialvalue", inputValues);
  };

  const handleSubmit = async (e, method) => {
    try {
      e.preventDefault();
      console.log("==========>", e);

      const {
        name,
        price,
        description,
        deliveryFee,
        quantity,
        category,
        vegorNonveg,
        subCategory,
      } = inputValues;

      console.log("dhgdgfhd", inputValues);

      let formData = new FormData();

      formData.append("name", name);
      formData.append("price", price);
      formData.append("description", description);
      formData.append("deliveryFee", deliveryFee);
      formData.append("quantity", quantity);
      formData.append("category", category);
      formData.append("subCategory", subCategory);
      formData.append("skuID", null);
      formData.append("vegorNonveg", vegorNonveg);

      if (file) formData.append("image", file);

      let data;
      setLoading(true);
      try {
        if (method === "post") {
          data = await axios.post(`${env.apiurl}/foods/add-food`, formData, {
            headers: { Authorization: `Bearer ${token}`, adminid: userId },
          });
          setLoading(false);
        } else if (method === "update") {
          data = await axios.put(
            `${env.apiurl}/foods/update/food/${localStorage.getItem(
              "SelectedProductId"
            )}`,
            formData,
            {
              headers: { Authorization: `Bearer ${token}`, adminid: userId },
            }
          );
          setLoading(false);
        }

        // console.log("object", data);
        if (data?.data.statusCode === 200) {
          if (method === "post") {
            toast.success("Product Added", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setInputValues({
              name: "",
              price: "",
              // price: 0,
              quantity: "",
              description: "",
              deliveryFee: "",
              category: "",
              subCategory: "",
              vegorNonveg: "",
            });
            handleRemove(e);
          } else {
            toast.success("Product Updated", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          setShow(false);
          setShowEdit(false);
          if (method === "update") {
            window.location.reload(true);
          } else {
            loadData();
          }
        } else {
          toast.error(`${data?.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        console.log(error);
        if (method === "post") {
          toast.error(data?.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(data?.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!file) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const handleRemove = async (e) => {
    e.preventDefault();
    setFile(undefined);
    setPreview();
    // if (method === "update" && preview != null) {
    //   let productId = localStorage.getItem("SelectedProductId");
    //   try {
    //     setLoading(true);
    //     let res = await axios.delete(
    //       `${env.apiurl}/foods/delete-food-image/${productId}`,
    //       {
    //         headers: { Authorization: `Bearer ${token}`, adminid: userId },
    //       }
    //     );
    //     setLoading(false);
    //     if (res.data.statusCode === 200) {
    //       toast.error("File Removed", {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     }
    //   } catch (error) {
    //     setLoading(false);
    //   }
    // }
  };
  useEffect(() => {
    loadData();
  }, []);
  // Change page
  const paginate = (pageNumber) => {
    setColumnNum((pageNumber - 1) * 10);
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };
  // Get data for pagination
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  //search query function
  let searchQuery = data.filter((products) =>
    products.name.toString().toLowerCase().includes(query.toLowerCase())
  );
  searchQuery = searchQuery.slice(indexOfFirstPost, indexOfLastPost);
  const handleQueryinput = (event) => {
    if (location.pathname === "/dashboard") {
      setQuery(event.target.value);
    }
  };

  return (
    <>
      {loading ? <LoadingSpinner /> : null}
      <div>
        {/* list adll product */}
        <div className="Main_header">
          <AdminHeader onChange={(e) => handleQueryinput(e)} />
          <AdminNav />
        </div>
        <div className="container">
          <div className="pageInfo">
            <div>
              <h2 className="heading24_bold">Product</h2>
            </div>
            <div>
              <button
                className="commonButton RoyalFlycatcherCrestColor"
                onClick={handleShow}
              >
                <img className="mr_8" src={AddIcon} /> <span>Add Product</span>
              </button>
            </div>
          </div>
          <div className="TableWrapper tableResponsive">
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Image</th>
                  {/* <th>Id</th> */}
                  <th>Title</th>
                  <th>Category</th>
                  <th>Sub-Category</th>
                  <th>Price</th>
                  <th>Availability</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {searchQuery.length > 0
                  ? searchQuery.map((product, index) => {
                    columnNumInc = columnNumInc + 1;
                    return (
                      <tr key={index}>
                        <td>{columnNumInc}</td>
                        <td className="ProductImg">
                          <img
                            src={product.image ? product.image : null}
                            alt=""
                          />
                        </td>
                        {/* <td>{product._id}</td> */}
                        <td>{product.name}</td>
                        {/* <td>{product.category}</td> */}
                        <td className="admincategFrame">
                          {product.category}
                        </td>
                        <td className="admincategFrame">
                          {product.subCategory ? product.subCategory : "__"}
                        </td>
                        <td>{product.price}</td>
                        <td>{product.quantity}</td>
                        <td>
                          <div className="displayFlex alignItem_Center">
                            <div className="mr_12">
                              <button
                                className="Edit_btn"
                                onClick={(e) => handleShowEdit(e, product)}
                              >
                                <img src={EditIcon} />
                              </button>
                            </div>
                            <div>
                              <button
                                className="Delete_btn"
                                onClick={() => handleDelete(product._id)}
                              >
                                <img src={DeleteIcon} />
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                  : data.length > 0 && (
                    <tr>
                      <td colspan="6" className="textAlign_center">
                        <img src={NodataImage} />
                        <h3 className="nodataTitle">
                          Sorry! No result found :(
                        </h3>
                        <p className="subDescription_text">
                          Sorry we couldn't find any matches for the product
                        </p>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={data.length}
            paginate={paginate}
          />
        </div>

        {/* add */}
        <Modal show={show} onHide={handleClose} className="addnewpopup">
          <Modal.Header>
            <div>
              <h4>Add New Product </h4>
            </div>
            <div>
              <button className="emptyBtn" onClick={handleClose}>
                <img src={CloseIcon} />
              </button>
            </div>
          </Modal.Header>
          <form>
            <div className="modelScroll">
              <Modal.Body>
                <div className="row mb_16">
                  <div className="col-md-3">
                    <label className="grayUpperLabel">Choose an image</label>
                  </div>
                  <div className="col-md-9">
                    <div className="mb_16">
                      <label htmlFor="ChooseAnImage" className="ImagePanel">
                        <img className="mr_12" src={UploadIcon} />
                        <span>Browse to add Images</span>

                        <input
                          type="file"
                          name="image"
                          accept="image/png, image/jpg, image/jpeg, image/gif"
                          onChange={handleFile}
                          id="ChooseAnImage"
                          hidden
                        />
                      </label>
                    </div>
                    {file ? (
                      <div className="image_parent">
                        <div className="imagePlaceholder">
                          <a
                            href="#"
                            className="closeIconforUpload"
                            onClick={(e) => {
                              handleRemove(e);
                            }}
                          >
                            <img src={UploadimagCloseIcon} />
                          </a>
                          <img
                            className="upload_img"
                            src={preview}
                            alt="image"
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row mb_16">
                  <div className="col-md-3">
                    <label className="grayUpperLabel">Product Details</label>
                  </div>
                  <div className="col-md-9">
                    <div className="mb_16">
                      <label className="inputTitle mb_8">Product Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="MainInput"
                        placeholder="Enter Product Name"
                        onChange={handleChange}
                        required
                      // value={values.name}
                      />
                    </div>
                    <div className="mb_16">
                      <label className="inputTitle mb_8">Product Price</label>


                      <div className="FormGroup">
                        <div className="inputGroup_prepand">
                          <span className="formGroupText">
                            <input
                              type="text"
                              className="dollerInput"
                              value={symbol}
                              disabled
                            // value={values.price}
                            />
                          </span>
                        </div>
                        <input
                          type="number"
                          id="price"
                          name="price"
                          value={inputValues?.price}
                          placeholder="Enter product Price"
                          onChange={handleChange}
                          required
                        // value={values.price}
                        />


                      </div>
                    </div>
                    <div className="mb_16">
                      <label className="inputTitle mb_8">
                        Available Quantity
                      </label>
                      <input
                        type="number"
                        className="MainInput"
                        id="quantity"
                        name="quantity"
                        value={inputValues?.quantity}
                        placeholder="Available Quantity"
                        onChange={handleChange}
                        required
                      // value={values.quantity}
                      />
                    </div>
                    <div className="mb_16">
                      <label className="inputTitle mb_8">
                        Product Category
                      </label>
                      <select
                        // onClick={handleChange}
                        onChange={handleGetSubCat}
                        className="MainInput"
                        name="category"
                        required
                        value={inputValues.category}
                      >
                        <option value="" disabled selected>
                          Select Category
                        </option>
                        {getCat?.map((e, i) => {
                          return (
                            <option value={e.categoryName} key={i}>
                              {e.categoryName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="mb_16">
                      <label className="inputTitle mb_8">Subcategory</label>
                      <select
                        value={inputValues?.subCategory}
                        onChange={handleChange}
                        className="MainInput"
                        name="subCategory"
                        required
                        disabled={getsub.length === 0}
                      >
                        <option value="" disabled selected>
                          Select Subcategory
                        </option>
                        {getsub?.map((e, i) => {
                          return (
                            <option value={e.subcategory} key={i}>
                              {e.subcategory}
                            </option>
                          );
                        })}
                      </select>
                      {getsub.length === 0 ? "No subcategory" : ""}
                    </div>
                    <div className="mb_16">
                      <label className="inputTitle mb_8">Veg or Non-Veg</label>
                      <select
                        onChange={handleChange}
                        className="MainInput"
                        name="vegorNonveg"
                        required
                      >
                        <option>Select Veg/Non-Veg</option>
                        <option value="veg">Veg</option>
                        <option value="nonveg">Non-Veg</option>
                      </select>
                    </div>
                    <div className="mb_16">
                      <label className="inputTitle mb_8">Delivery Charge</label>
                      <div className="FormGroup">
                        <div className="inputGroup_prepand">
                          <span className="formGroupText">
                            <input
                              type="text"
                              value={symbol}
                              className="dollerInput"
                              disabled
                            // value={values.price}
                            />
                          </span>
                        </div>
                        <input
                          type="number"
                          className="MainInput"
                          id="deliveryFee"
                          name="deliveryFee"
                          value={inputValues?.deliveryFee}
                          placeholder="Delivery Charge"
                          onChange={handleChange}
                          // value={values.quantity}
                          required
                        />

                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label className="grayUpperLabel" htmlFor="description">
                      Description
                    </label>
                  </div>
                  <div className="col-md-9">
                    <div>
                      <label className="inputTitle mb_8">
                        Product Description
                      </label>

                      <textarea
                        className="textarea"
                        name="description"
                        id="description"
                        placeholder="Description"
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </div>
          </form>
          <Modal.Footer>
            <div>
              <button className="popupCancelButton" onClick={handleClose}>
                Cancel
              </button>
            </div>
            <div>
              <button
                className="popupMainButton"
                type="submit"
                onClick={(e) => handleSubmit(e, "post")}
              >
                Add
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* edit */}
        <Modal show={showedit} onHide={handleCloseEdit} className="addnewpopup">
          <Modal.Header>
            <div>
              <h4>Edit Product </h4>
            </div>
            <div>
              <button className="emptyBtn" onClick={handleCloseEdit}>
                <img src={CloseIcon} />
              </button>
            </div>
          </Modal.Header>
          <form>
            <div className="modelScroll">
              <Modal.Body>
                <div className="row mb_16">
                  <div className="col-md-3">
                    <label className="grayUpperLabel">Choose an image</label>
                  </div>
                  <div className="col-md-9">
                    <div className="mb_24">
                      <label htmlFor="ChooseAnImage" className="ImagePanel">
                        <img className="mr_12" src={UploadIcon} />

                        <span>Browse to add Images</span>

                        <input
                          type="file"
                          name="image"
                          accept="image/png, image/jpg, image/jpeg, image/gif"
                          onChange={handleFile}
                          id="ChooseAnImage"
                          hidden
                        />
                      </label>
                    </div>

                    <div className="image_parent">
                      <div className="imagePlaceholder">
                        {/* <a
                          href="#"
                          className="closeIconforUpload"
                          onClick={(e) => {
                            handleRemove(e, "update");
                          }}
                        >
                          <img src={UploadimagCloseIcon} />
                        </a> */}
                        <img src={preview} className="upload_img" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb_16">
                  <div className="col-md-3">
                    <label className="grayUpperLabel">Product Details</label>
                  </div>
                  <div className="col-md-9">
                    <div className="mb_16">
                      <label className="inputTitle mb_8" htmlFor="name">
                        Product Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="MainInput"
                        placeholder="Enter Product Name"
                        onChange={handleChange}
                        defaultValue={selectedFood.name}
                      />
                    </div>
                    <div className="mb_16">
                      <label htmlFor="price" className="inputTitle mb_8">
                        Product Price
                      </label>
                      <div className="FormGroup">
                        <div className="inputGroup_prepand">
                          <span className="formGroupText">
                            <input
                              type="text"
                              value={symbol}
                              className="dollerInput"
                              disabled
                            // value={values.price}
                            />
                          </span>
                        </div>

                        <input
                          type="number"
                          id="price"
                          name="price"
                          className="MainInput"
                          value={inputValues?.price}
                          placeholder="Enter product Price"
                          onChange={handleChange}
                        // defaultValue={selectedFood.price}
                        />

                      </div>
                    </div>
                    <div className="mb_16">
                      <label htmlFor="quantity" className="inputTitle mb_8">
                        Available Quantity
                      </label>

                      <input
                        type="number"
                        className="MainInput"
                        id="quantity"
                        name="quantity"
                        placeholder="Available Quantity"
                        value={inputValues?.quantity}
                        onChange={handleChange}
                      // defaultValue={selectedFood.quantity}
                      />
                    </div>
                    <div className="mb_16">
                      <label className="inputTitle mb_8">
                        Product Category
                      </label>
                      <select
                        onClick={handleChange}
                        onChange={handleGetSubCat}
                        className="MainInput"
                        name="category"
                        defaultValue={inputValues.category}
                      // defaultValue={inputValues.category}
                      >
                        <option value="" disabled selected>
                          Select Category
                        </option>

                        {getCat?.map((e, i) => {
                          return (
                            <option value={e.categoryName} key={i}>
                              {e.categoryName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="mb_16">
                      <label className="inputTitle mb_8">Subcategory</label>
                      <select
                        onChange={(e) => {
                          setInputValues({
                            ...inputValues,
                            subCategory: e.target.value,
                          });
                          // console.log(e.target.value, inputValues);
                        }}
                        //  onClick={handleGetSubCat}

                        className="MainInput"
                        name="subCategory"
                        value={inputValues?.subCategory}
                        required
                        disabled={getsub.length === 0}
                      >
                        <option value="" disabled selected>
                          Select Subcategory
                        </option>
                        {getsub?.map((e, i) => {
                          return (
                            <option value={e.subcategory} key={i}>
                              {e.subcategory}
                            </option>
                          );
                        })}
                      </select>
                      {getsub.length === 0 ? "No subcategory" : ""}
                    </div>
                    <div className="mb_16">
                      <label className="inputTitle mb_8">Veg or Non-Veg</label>
                      <select
                        onChange={handleChange}
                        className="MainInput"
                        name="vegorNonveg"
                        defaultValue={selectedFood.vegorNonveg}
                      >
                        <option>Select Veg/Non-Veg</option>
                        <option value="veg">Veg</option>
                        <option value="nonveg">Non-Veg</option>
                      </select>
                    </div>
                    <div className="mb_16">
                      <label htmlFor="deliveryFee" className="inputTitle mb_8">
                        Delivery Charge
                      </label>
                      <div className="FormGroup">
                        <div className="inputGroup_prepand">
                          <span className="formGroupText">
                            <input
                              type="text"
                              value={symbol}
                              className="dollerInput"
                              disabled
                            // value={values.price}
                            />
                          </span>
                        </div>
                        <input
                          type="number"
                          id="deliveryFee"
                          name="deliveryFee"
                          className="MainInput"
                          placeholder="deliveryFee"
                          value={inputValues?.deliveryFee}
                          onChange={handleChange}
                        // defaultValue={selectedFood.deliveryFee}
                        />

                      </div>
                    </div>
                  </div>
                </div>


                <div className="row">
                  <div className="col-md-3">
                    <label className="grayUpperLabel"> Description</label>
                  </div>
                  <div className="col-md-9">
                    <div>
                      <label htmlFor="description" className="inputTitle mb_8">
                        Product Description
                      </label>

                      <textarea
                        name="description"
                        id="description"
                        className="textarea"
                        placeholder="Description"
                        onChange={handleChange}
                        defaultValue={selectedFood.description}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </div>
          </form>
          <Modal.Footer>
            <div>
              <button className="popupCancelButton" onClick={handleCloseEdit}>
                Cancel
              </button>
            </div>
            <div>
              <button
                className="popupMainButton"
                type="submit"
                onClick={(e) => handleSubmit(e, "update")}
              >
                Update
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <ToastContainer autoClose={1800} />
      </div>
    </>
  );
};

export default ProductDashboard;
