import axios from "axios";
import { useEffect, useState, useContext } from "react";
import env from "../../enviroinment";
import UserHeader from "./UserHeader";
import EmptyImg from "../../assets/images/empty_img.svg";
import CheckboxIcon from "../../assets/images/checkbox_circle.svg";
import PencilIcon from "../../assets/images/pencil_icon.svg";
import CirclecloseIcon from "../../assets/images/Circleclose_icon.svg";
import { useNavigate } from "react-router-dom";
import BackarrowIcon from "../../assets/images/backarrow_icon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import UploadimagCloseIcon from "../../assets/images/uploadImg_closeIcon.svg";
import { toast } from "react-toastify";
import MobileMenu from "./MobileMenu";
import LoadingSpinner from "../loadingSpinner";
import { CartContext } from "../../App";

const UserProfile = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [editUserName, setUserName] = useState(false);
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const [profilePicURL, setProfilePicURL] = useState(EmptyImg);
  const navigate = useNavigate();
  const context = useContext(CartContext);

  const FILE_SIZE = 20 * 1024 * 1024;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/webp",
  ];

  const customerId = localStorage.getItem("CustomerId");
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const business = localStorage.getItem("businessName");

  const redirectToHome = (redirectRoute) => {
    if (redirectRoute == "home") navigate(`/user-menu/${business}`);
  };

  const GetUserData = () => {
    setLoading(true);
    axios
      .get(`${env.apiurl}/users/${customerId}`, {
        headers: { Authorization: `Bearer ${token}`, adminid: userId },
      })
      .then((res) => {
        setLoading(false);
        res.data.user.mobile = res.data.user.mobile.split("+91");
        updateUserNameForm.setFieldValue("name", res.data.user.contactName);
        setUser(res.data.user);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    GetUserData();
  }, []);

  const [fix, setFix] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setFix(true);
      } else if (window.scrollY === 0) {
        setFix(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const updateUserNameForm = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is required")
        .max(30, "Name should't exceed more than 30 characters"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      axios
        .put(`${env.apiurl}/users/update-name/${customerId}`, {
          contactName: values.name,
        })
        .then((res) => {
          setLoading(false);
          if (res.data.statusCode === 200) {
            console.log(user);
            console.log({ ...user, contactName: values.name });
            setUser({ ...user, contactName: values.name });
          }
        })
        .catch((error) => {
          setLoading(false);
        });
      setUserName(false);
    },
  });

  const addProfilePictureForm = useFormik({
    initialValues: {
      profilePic: "",
    },
    validationSchema: Yup.object({
      profilePic: Yup.mixed()
        .required("A file is required")
        .test(
          "fileSize",
          "File too large",
          (value) => value && value.size <= FILE_SIZE
        )
        .test(
          "fileFormat",
          "Unsupported Format",
          (value) => value && SUPPORTED_FORMATS.includes(value.type)
        ),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("image", file);
      setLoading(true);
      axios
        .put(`${env.apiurl}/users/update-profile-pic/${customerId}`, formData, {
          headers: {
            Authorization: `Bearer ${values.customerToken}`,
            adminid: userId,
          },
        })
        .then((res) => {
          setLoading(false);
          if (res.data.statusCode === 200) {
            addProfilePictureForm.setFieldValue("profilePic", "");
            setFile(undefined);
            const objectUrl = URL.createObjectURL(file);
            setUser({ ...user, profilePic: objectUrl });
            toast.success("Profile Picture Updated Successfully");
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    },
  });

  console.log(user);

  useEffect(() => {
    if (!file) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);


  const logoutHandle = () => {
    const Merchant = localStorage?.getItem("businessName");
    localStorage.clear();
    context.setCart([]);
    navigate(`/user-menu/${Merchant}`)
  }

  return (
    <>
      {loading ? <LoadingSpinner /> : null}
      <div className="bgFitUser">
        <div className={fix ? "sticky_top" : "mobiletopHeader mb_16"}>
          <div className="container">
            <div className="displayFlex alignItem_Center justifyContent_spaceBetween">
              <div>
                <a
                  onClick={() => navigate(`/user-menu/${business}`)}
                  style={{ cursor: "pointer" }}
                >
                  <img src={BackarrowIcon} />
                </a>
              </div>
              <div>
                <h5 className="blackgraytext textAlign_center">Profile</h5>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="userProfile">
          {file ? (
            <div
              style={{ justifyContent: "space-around" }}
              className="image_parent mb_30"
            >
              <div className="imagePlaceholder">
                <a
                  className="closeIconforUpload"
                  onClick={() => {
                    addProfilePictureForm.setFieldValue("profilePic", "");
                    setFile(undefined);
                  }}
                >
                  <img src={UploadimagCloseIcon} />
                </a>
                <img src={preview} alt="image" className="upload_img" />
              </div>
            </div>
          ) : (
            <div className="profile_img">
              <img
                src={user?.profilePic ? user.profilePic : EmptyImg}
                alt="user"
              />
            </div>
          )}

          <form onSubmit={addProfilePictureForm.handleSubmit}>
            <div className="mb_24" style={{ textAlign: "center" }}>
              <label htmlFor="profilePic">
                <span className="primarySmallBtn margin_auto displayBlock">
                  Browse to Add Picture
                </span>
                <input
                  type="file"
                  onBlur={addProfilePictureForm.handleBlur}
                  name="profilePic"
                  accept="image/png, image/jpg, image/jpeg, image/gif, image/webp"
                  id="profilePic"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                    addProfilePictureForm.setFieldValue(
                      "profilePic",
                      e.currentTarget.files[0]
                    );
                  }}
                  hidden
                />
              </label>

              {addProfilePictureForm.touched.profilePic &&
                addProfilePictureForm.errors.profilePic &&
                file ? (
                <p className="error_text">
                  {addProfilePictureForm.errors.profilePic}
                </p>
              ) : null}
            </div>

            {file ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <button
                  style={{ position: "initial", width: "initial" }}
                  className="AddButton"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            ) : null}
          </form>
          <div className="p16">
            <form>
              <table className="userdetailTable">
                <tbody>
                  <tr>
                    <th>Name</th>
                    {editUserName ? (
                      <td>
                        <input
                          type="text"
                          placeholder="Enter Your Name"
                          className="MainInput"
                          name="name"
                          value={updateUserNameForm.values.name}
                          onChange={updateUserNameForm.handleChange}
                          onBlur={updateUserNameForm.handleBlur}
                        />

                        {console.log(
                          updateUserNameForm.touched,
                          updateUserNameForm.errors
                        )}

                        {updateUserNameForm.touched.name &&
                          updateUserNameForm.errors.name ? (
                          <p className="error_text">
                            {updateUserNameForm.errors.name}
                          </p>
                        ) : null}
                      </td>
                    ) : (
                      <td>{user ? user.contactName : ""}</td>
                    )}
                    <td>
                      <div className="displayFlex alignItem_Center gap_8">
                        {!editUserName ? (
                          <div>
                            <button
                              onClick={() => setUserName(true)}
                              className="emptyBtn"
                            >
                              <img src={PencilIcon} />
                            </button>
                          </div>
                        ) : (
                          ""
                        )}

                        {editUserName ? (
                          <>
                            <div>
                              <button
                                onClick={updateUserNameForm.handleSubmit}
                                type="submit"
                                className="emptyBtn"
                              >
                                <img src={CheckboxIcon} />
                              </button>
                            </div>

                            <div>
                              <button
                                onClick={() => setUserName(false)}
                                className="emptyBtn"
                              >
                                <img src={CirclecloseIcon} />
                              </button>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td colSpan={2}>{user ? user.contactMailAddress : ""}</td>
                  </tr>
                  <tr>
                    <th>Mobile</th>
                    <td colSpan={2}>{user ? user.mobile : ""}</td>
                  </tr>
                  <tr>
                    <th></th>
                    <td colSpan={2}><button className="GreenButton" type="button" onClick={logoutHandle}>Logout</button></td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
        <MobileMenu redirectToHome={redirectToHome} />
      </div>
    </>
  );
};

export default UserProfile;
