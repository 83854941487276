import React, { useState, useEffect, useContext } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import SearchIcon from '../../assets/images/search_icon.svg'
import NotificationIcon from '../../assets/images/notification_icon.svg'
import UseravatarIcon from '../../assets/images/useravatar_icon.svg'
import Mainlogo from '../../assets/images/logo.svg'
// import logoutIcon from "../../assets/images/logout_icon.svg";
import { useNavigate } from 'react-router-dom'
import env from '../../enviroinment'
import axios from 'axios'
import { userContext } from '../../App'
import LoadingSpinner from '../loadingSpinner'

function AdminHeader({ onChange }) {
  const [loading, setLoading] = useState(false);
  const context = useContext(userContext);
  const [logo, setLogo] = useState('')
  let userId = localStorage.getItem('userId')
  const navigate = useNavigate()
  const handleOut = () => {
    localStorage.clear()
    context.setloggedIn(localStorage.getItem('token'))
    navigate('/login')
  }
  const gotoChangepw = () => {
    navigate('/change-password')
  }
  const gotoProfile = () => {
    navigate('/admin-profile')
  }
  const gotoPayment = () => {
    navigate('/business-upi')
  }

  //show business logo in header
  let loadBusinessLogo = async () => {
    try {
      setLoading(true);
      let res = await axios.get(`${env.apiurl}/admin/getadminlogo`, {
        headers: { adminid: userId },
      })
      setLoading(false);
      if (res.data.statusCode === 200) {
        let array = []
        const { businesslogo } = res.data
        let object = {
          businesslogo: businesslogo,
        }
        array.push(object)
        setLogo(array)
      }
    } catch (error) {
      setLoading(false);
    }

  }
  useEffect(() => {
    loadBusinessLogo()
  }, [])
  return (
    <>
      {loading ? <LoadingSpinner /> : null}
      <div className="container">
        <div className="topHeader">
          <div className="adminLogo">
            <img src={Mainlogo} />
          </div>

          <div className="userDetail">
            <div className="DesktopSearch_on">
              <div className="SearchForm">
                <div className="input-group-prepend">
                  <span className="inputGroup_text">
                    <img src={SearchIcon} />
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Search"
                  className=""
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="userSetting">
              <ul>
                {/* <li>
                <a href="#" className="CirclecountBox">
                  <img src={NotificationIcon} />
                  <span>0</span>
                </a>
              </li> */}
                <li className="userprofile">
                  <Dropdown>
                    <Dropdown.Toggle>
                      {logo &&
                        logo.map((item, index) => (
                          <img
                            className="userImg mr_12"
                            key={index}
                            src={item.businesslogo ? item.businesslogo : null}
                            alt=""
                          />
                        ))}
                      <span className="mr_12">Admin</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={gotoProfile}>
                        Admin profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={gotoPayment}>
                        Payment Details
                      </Dropdown.Item>
                      <Dropdown.Item onClick={gotoChangepw}>
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleOut}>
                        {/* <img className="mr_12" src={logoutIcon} /> */}
                        <span>Logout</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="adminMobile_on">
          <div className="SearchForm">
            <div className="input-group-prepend">
              <span className="inputGroup_text">
                <img src={SearchIcon} />
              </span>
            </div>
            <input
              type="text"
              placeholder="Search"
              className=""
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminHeader
