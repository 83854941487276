import React, { useState, useEffect } from 'react'
import axios from 'axios'
import env from '../../enviroinment'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import UploadIcon from '../../assets/images/upload_icon.svg'
import UploadimagCloseIcon from '../../assets/images/uploadImg_closeIcon.svg'
import LoadingSpinner from '../loadingSpinner'

const Payment = () => {
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate()
  let userId = localStorage.getItem('userId')
  let [file, setFile] = useState()
  const [preview, setPreview] = useState();


  let [input, setInput] = useState({
    paymentUpi: 'ABCXXX@Theecode',
    upiMobile: '1234567890',
    upiName: 'Theecode',
    businessQr: '',
  })
  const handleChange = (e) => {
    e.preventDefault()
    const input_name = e.target.name
    const input_value = e.target.value
    setInput({ ...input, [input_name]: input_value })
  }
  const handleFile = (e) => {
    e.preventDefault()
    let selectedFile = e.target.files[0]
    setFile(selectedFile)
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      const { paymentUpi, upiMobile, upiName, businessQr } = input
      let formData = new FormData()
      formData.append('paymentUpi', paymentUpi)
      formData.append('upiMobile', upiMobile)
      formData.append('upiName', upiName)
      // formData.append("businessQr", businessQr)
      if (file) formData.append('image', file)
      setLoading(true);
      let res = await axios.post(`${env.apiurl}/payments`, formData, {
        headers: { adminid: userId },
      })
      setLoading(false);
      if (res.data.statusCode === 200) {
        toast.success('Account Successfully Created')
        navigate('/login')
      } else {
        toast.error(res.data.message)
      }
    } catch (error) {
      console.log(error)
      setLoading(false);
    }

  }
  useEffect(() => {
    if (!file) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(file)
    setPreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
  }, [file])
  const handleRemove = (e) => {
    e.preventDefault()
    setFile(undefined)
    return
  }
  return (
    <>
      {loading ? <LoadingSpinner /> : null}
      <div className="height_100per">
        <div className="displayFlex height_100per alignItem_Center justifyContent_Center">
          <div className="WhiteFrame CenterScreen">
            <h2 className="FrameHeading_28 textAlign_center">
              Payment <span className="hightLightThemeClr"> Details !</span>
            </h2>
            <p className="subDescription_text textAlign_center">
              Please Make Sure to give a Merchant UPI!
            </p>
            <div className="title_border"></div>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="mb_24">
                <label className="mb_8 inputTitle">UPI ID</label>
                <input
                  type="text"
                  id="paymentUpi"
                  name="paymentUpi"
                  placeholder="Enter UPI ID"
                  className="MainInput"
                  onChange={handleChange}
                  value={input.paymentUpi}
                  readOnly
                />
              </div>

              <div className="mb_24">
                <label className="mb_8 inputTitle">UPI Name</label>
                <input
                  type="text"
                  id="upiName"
                  name="upiName"
                  placeholder="UPI Name"
                  className="MainInput"
                  onChange={handleChange}
                  value={input.upiName}
                  readOnly
                />
              </div>

              <div className="mb_24">
                <label className="mb_8 inputTitle">UPI Mobile</label>
                <input
                  type="text"
                  id="upiMobile"
                  name="upiMobile"
                  placeholder="UPI Mobile"
                  className="MainInput"
                  onChange={handleChange}
                  value={input.upiMobile}
                  readOnly
                />
              </div>

              <label className="mb_8 inputTitle">Upload UPI QR Code</label>
              <div className="mb_24">
                <label htmlFor="businessLogo" className="ImagePanel">
                  <img className="mr_12" src={UploadIcon} />
                  <span>Browse to add QR Code</span>
                  <input
                    type="file"
                    name="businessLogo"
                    accept="image/png, image/jpg, image/jpeg, image/gif"
                    id="businessLogo"
                    onChange={handleFile}
                    hidden

                  />
                </label>
              </div>
              {file ? (
                <div className="image_parent mb_30">
                  <div className="imagePlaceholder">
                    <a className="closeIconforUpload" onClick={handleRemove}>
                      <img src={UploadimagCloseIcon} />
                    </a>
                    <img src={preview} alt="image" className="upload_img" />
                  </div>
                </div>
              ) : null}
              <button className="MainButton margin_auto" type="submit">
                Add Account
              </button>
            </form>
            <ToastContainer autoClose={1800} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Payment
