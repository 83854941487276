import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import AdminloginBG from "../../assets/images/foodTime.jpg";
import UploadimagCloseIcon from "../../assets/images/uploadImg_closeIcon.svg";
import UploadIcon from "../../assets/images/upload_icon.svg";
import Mainlogo from "../../assets/images/logo.svg";
import { CurrencySymbol } from ".././common/currencySymbol";
import { Currency } from ".././common/currency";
import EyeshowIcon from "../../assets/images/eye_show.svg";
import EyehideIcon from "../../assets/images/eye_hide.svg";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import env from "../../enviroinment";
import LoadingSpinner from "../loadingSpinner";
function SignUp() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const [companyId, setCompanyId] = useState();
  const [currency, setCurrency] = useState("");
  const [phonecode, setPhoneCode] = useState("");
  const [type, setType] = useState(true)
  const FILE_SIZE = 20 * 1024 * 1024;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/webp",
  ];
  // let handleType = ()=>{
  //   if(type === true){
  //     setType(false)
  //   }else{
  //     setType(true)
  //   }
  // }

  const formik = useFormik({
    initialValues: {
      adminName: "",
      businessName: "",
      businessType: "",
      businessLogo: "",
      email: "",
      mobile: "",
      password: "",
      cpassword: "",
      country: "",
      currency: "",
      currencySymbol: "",
      phonecode: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      country: Yup.string().required("Country is required"),
      adminName: Yup.string().max(255).required("Admin name is required"),
      businessName: Yup.string().max(255).required("Business name is required"),
      businessType: Yup.string().max(255).required("Business type is required"),
      businessLogo: Yup.mixed()
        .required("File is required")
        .test(
          "fileSize",
          "File too large",
          (value) => value && value.size <= FILE_SIZE
        )
        .test(
          "fileFormat",
          "Unsupported Format",
          (value) => value && SUPPORTED_FORMATS.includes(value.type)
        ),
      email: Yup.string()
        .email("Field should contain a valid e-mail")
        .max(255)
        .required("E-mail is required"),
      mobile: Yup.string().max(255).required("Mobile number is required"),
      password: Yup.string().max(255).required("Password is required"),
      cpassword: Yup.string()
        .max(255)
        .required("Kindly confirm your password")
        .oneOf([Yup.ref("password"), null], "Password must match"),
    }),
    onSubmit: async (values) => {
      var md5 = require("md5");
      var hash_pwd = md5(values?.password);
      // console.log(hash_pwd, "HASH_PWD");
      // console.log(values)

      let formData = new FormData();
      formData.append("image", file);
      formData.append("adminName", values.adminName);
      formData.append(
        "businessName",
        values.businessName.toLowerCase().replace(/ /g, "-")
      );
      formData.append("businessType", values.businessType);
      formData.append("businessLogo", values.businessLogo);
      formData.append("email", values.email);
      formData.append("mobile", values.mobile);
      formData.append("password", values.password);
      formData.append("country", values.country);
      formData.append("currency", values.currency);
      formData.append("phonecode", values.phonecode);
      formData.append("currencySymbol", values.currencySymbol);
      setLoading(true);
      const res = await axios.post(`${env.apiurl}/admin/signup`, formData);
      setLoading(false);
      if (res.data.statusCode === 200) {
        setLoading(true);
        await axios
          .post(`https://admin-api.sayshop.co/api/Companies/updateCompany`, {
            id: 0,
            market_id: 1,
            email_for_customers: values.email,
            website_url: values.businessName.split(" ").join(""),
            business_name: values.businessName.split(" ").join(""),
            custom_domain_url: values.businessName.split(" ").join(""),
            replicated_website_url: values.businessName.split(" ").join(""),
          })
          .then((response) => {
            setLoading(false);
            console.log(response.data, "UPDATE_COMPANY");
            if (response?.status === 200) {
              let company_id = response?.data;
              setLoading(true);
              axios
                .post(`https://admin-api.sayshop.co/User/addUser`, {
                  company_id: company_id,
                  email: values.email,
                  encrypted_password: hash_pwd,
                  first_name: values.adminName,
                  last_name: values.adminName,
                  market_id: 1,
                  parent_id: 1,
                  region_id: 4,
                  phone_number: values.mobile.toString,
                  roles: "user",
                  status: 1,
                  user_level: "A",
                })
                .then((resp) => {
                  setLoading(true);
                  axios
                    .post(`https://website-api.sayshop.co/api/Website`, {
                      user_id: parseInt(resp?.data?.result),
                    })
                    .then((res) => {
                      setLoading(false);
                      console.log(res.data, "Website");
                      let companyData = new FormData();
                      companyData.append("companyId", response?.data);
                      companyData.append(
                        "businessName",
                        values?.businessName.toLowerCase().replace(/ /g, "-")
                      );
                      companyData.append("email", values?.email);
                      companyData.append("parentId", resp?.data?.result);
                      axios.post(`${env.apiurl}/admin/signup`, companyData);
                    })
                    .catch((error) => {
                      setLoading(false);
                    });
                })
                .catch((error) => {
                  setLoading(false);
                });
            }
          })
          .catch((error) => {
            setLoading(false);
          });

        localStorage.setItem("userId", res.data.user._id);
        toast.success("Account Successfully Created");
        navigate("/upi");
      } else {
        toast.error(res.data.message);
      }
    },
  });

  const handleFile = (e) => {
    let selectedFile = e.target.files[0];
    setFile(selectedFile);
    console.log(selectedFile);
  };

  useEffect(() => {
    if (!file) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  useEffect(() => {
    getAllCountry();
  }, []);

  const getAllCountry = async () => {
    try {
      setLoading(true);
      let country = await axios.get(`${env.apiurl}/admin/getCountry`);
      setLoading(false);
      console.log(country);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleRemove = () => {
    formik.setFieldValue("businessLogo", undefined);
    setFile(undefined);
    return;
  };

  const gotoLogin = () => {
    navigate("/login");
  };

  const currencyRef = useRef();

  const countryChange = (e) => {
    let val = e.target.value;


    if (val) currencyRef.current.focus();

    let all = Currency.filter((e) => e.country_codes == val);
    console.log(all)

    formik.values.country = val;

    setCurrency(all[0].currency_codes);

    formik.values.currency = all[0].currency_codes;

    let symbol = CurrencySymbol.filter((e) => e.code == all[0].currency_codes);
    console.log(symbol[0].symbol_native);
    formik.values.currencySymbol = symbol[0].symbol_native;

    setPhoneCode(`+${all[0].mobile_code}`);
    formik.values.phonecode = `+${all[0].mobile_code}`;
    // console.log(formik.values)
  };

  return (
    <>
      {loading ? <LoadingSpinner /> : null}
      <div className=" container-fluid height_100per">
        <div className="row height_100per">
          <div
            className="accountLeftSide col-md-6 p0"
          // style={{
          //   backgroundImage: `url(${AdminloginBG})`,
          //   backgroundRepeat: 'no-repeat',
          //   backgroundSize: 'cover',
          // }}
          >
            <img src={AdminloginBG} />
          </div>
          <div className="accountRightSide col-md-6 p0 WhiteFrame">
            <div className="accountPageInner">
              <div className="logo_brand">
                <img src={Mainlogo} />
              </div>
              <h2 className="FrameHeading_28 textAlign_center">
                Welcome to <span className="hightLightThemeClr">SayShop</span>
              </h2>
              <p className="subDescription_text textAlign_center">
                Sign up to Continue.
              </p>
              <div className="title_border"></div>
              <form onSubmit={formik.handleSubmit}>
                <div className="row mb_16">
                  <div className="col-md-6 mobSpace_mb16">
                    <label className="mb_8 inputTitle">Name</label>
                    <input
                      type="text"
                      onBlur={formik.handleBlur}
                      id="adminName"
                      name="adminName"
                      placeholder="Enter Your Name"
                      className="MainInput"
                      onChange={formik.handleChange}
                      maxLength={15}
                    />
                    {formik.touched.adminName && formik.errors.adminName ? (
                      <p className="error_text">{formik.errors.adminName}</p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="mb_8 inputTitle">Business Name</label>
                    <input
                      type="text"
                      onBlur={formik.handleBlur}
                      id="businessName"
                      name="businessName"
                      placeholder="Business Name"
                      className="MainInput"
                      onChange={formik.handleChange}
                      maxLength={20}
                    />
                    {formik.touched.businessName &&
                      formik.errors.businessName ? (
                      <p className="error_text">{formik.errors.businessName}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="mb_8 inputTitle">Business Type</label>
                  <input
                    type="text"
                    onBlur={formik.handleBlur}
                    id="businessType"
                    name="businessType"
                    placeholder="Business Type"
                    className="MainInput"
                    onChange={formik.handleChange}
                    maxLength={20}
                  />
                  {formik.touched.businessType && formik.errors.businessType ? (
                    <p className="error_text">{formik.errors.businessType}</p>
                  ) : null}
                </div>

                <div className="row mb_16">
                  <div className="col-md-6 mobSpace_mb16">
                    <label className="mb_8 inputTitle">Country</label>
                    <select
                      className="MainInput"
                      name="mobile_code"
                      onChange={countryChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobile_code}
                    >
                      <option value="">Select Country </option>
                      {Currency?.map((code, i) => {
                        return (
                          code.active == true && (
                            <option key={i} value={code.country_codes}>
                              {code.name}
                            </option>
                          )
                        );
                      })}
                    </select>

                    {formik.errors.mobile_code && formik.touched.mobile_code ? (
                      <div className="error_text">
                        {formik.errors.mobile_code}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="mb_8 inputTitle">currency</label>
                    <input
                      type="text"
                      // onBlur={formik.handleBlur}
                      id="currency"
                      name="currency"
                      placeholder="currency"
                      className="MainInput"
                      // onChange={formik.handleChange}
                      value={currency}
                      readOnly
                    />
                    {formik.touched.currency && formik.errors.currency ? (
                      <p className="error_text">{formik.errors.currency}</p>
                    ) : null}
                  </div>
                </div>

                <div className="row mb_16">
                  <div className="mobSpace_mb16 col-md-6">
                    <label className="mb_8 inputTitle">Email address</label>
                    <input
                      ref={currencyRef}
                      type="text"
                      onBlur={formik.handleBlur}
                      id="email"
                      name="email"
                      placeholder="Email Address"
                      className="MainInput"
                      onChange={formik.handleChange}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <p className="error_text">{formik.errors.email}</p>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="mb_8 inputTitle">Phone No</label>

                    <div className="displayFlex alignItem_Center gap_16">
                      <div>
                        <input
                          type="text"
                          // onBlur={formik.handleBlur}
                          id="phonecode"
                          name="phonecode"
                          placeholder=" +44"
                          className="mainWidthoutPadding width_60"
                          // onChange={formik.handleChange}
                          value={phonecode}
                          readOnly
                        />
                      </div>
                      <div>
                        <input
                          type="text"
                          onBlur={formik.handleBlur}
                          id="mobile"
                          name="mobile"
                          placeholder="Phone No"
                          className="MainInput"
                          onChange={formik.handleChange}
                          maxLength={10}
                          value={formik.values.mobile?.replace(/[^\d]/g, "")}
                        />
                        {formik.touched.mobile && formik.errors.mobile ? (
                          <p className="error_text">{formik.errors.mobile}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb_16">
                  <div className="col-md-6 mobSpace_mb16">
                    <label className="mb_8 inputTitle">Password</label>

                    <input
                      type={"password"}
                      onBlur={formik.handleBlur}
                      style={{ position: "relative" }}
                      id="password"
                      name="password"
                      placeholder="Password"
                      className="MainInput"
                      onChange={formik.handleChange}
                    />

                    {formik.touched.password && formik.errors.password ? (
                      <p className="error_text">{formik.errors.password}</p>
                    ) : null}
                  </div>

                  <div className="col-md-6">
                    <label className="mb_8 inputTitle">Confirm Password</label>

                    <div className="inputGroup">
                      <input
                        type={type ? "password" : "text"}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        id="cpassword"
                        name="cpassword"
                        placeholder="Confirm Password"
                      />

                      <div className="inputGroup_append">
                        <span className="inputGroupText">
                          <img
                            src={type ? EyeshowIcon : EyehideIcon}
                            onClick={() => setType(!type)}
                            alt="eye_pic"
                          />
                        </span>
                      </div>
                    </div>

                    {formik.touched.cpassword && formik.errors.cpassword ? (
                      <p className="error_text">{formik.errors.cpassword}</p>
                    ) : null}
                  </div>
                </div>

                <label className="mb_8 inputTitle">Upload Business Logo</label>
                <div className="mb_24">
                  <label htmlFor="businessLogo" className="ImagePanel">
                    <img className="mr_12" src={UploadIcon} />
                    <span>Browse to add Logo</span>
                    <input
                      type="file"
                      onBlur={formik.handleBlur}
                      name="businessLogo"
                      accept="image/png, image/jpg, image/jpeg, image/gif, image/webp"
                      id="businessLogo"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "businessLogo",
                          event.currentTarget.files[0]
                        );
                        handleFile(event);
                      }}
                      hidden
                    />
                  </label>
                  {formik.touched.businessLogo && formik.errors.businessLogo ? (
                    <p className="error_text">{formik.errors.businessLogo}</p>
                  ) : null}
                </div>
                {file ? (
                  <div className="image_parent mb_30">
                    <div className="imagePlaceholder">
                      <a className="closeIconforUpload" onClick={handleRemove}>
                        <img src={UploadimagCloseIcon} />
                      </a>
                      <img src={preview} alt="image" className="upload_img" />
                    </div>
                  </div>
                ) : null}
                <button
                  className="MainButton margin_auto width_160 mb_16"
                  type="submit"
                >
                  Signup
                </button>

                <p className="subDescription_text textAlign_center">
                  Do you already have an account?
                  <a
                    onClick={gotoLogin}
                    className="cursorPointer textDecoration_unset ml_4"
                  >
                    Login
                  </a>
                </p>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={1800} />
      </div>
    </>
  );
}

export default SignUp;
