import axios from 'axios';
// import Cookies from 'js-cookie';
// import { localStorage } from './localStorage';

const url = process.env.KRTI_WEB_API;
let requestCounter = 0

export const KrtiPayService = axios.create({
    // baseURL: url,
    headers: {
        'Content-Type': "application/json",

    }
})




KrtiPayService.interceptors.request.use(function (config) {

    requestCounter++
    document.body.classList.add('loading-indicator');


    return config
}, function (error) {
    return Promise.reject(error);
});

KrtiPayService.interceptors.response.use(function (response) {

    requestCounter--;

    console.log("Req-count", requestCounter)
    if (requestCounter === 0)
        document.body.classList.remove('loading-indicator');

    return response;
}, function (error) {
    document.body.classList.remove('loading-indicator');

    return Promise.reject(error);
});


