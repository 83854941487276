import React, { useState, useEffect } from 'react'
import AdminNav from './AdminNav'
import AdminHeader from './AdminHeader'
import axios from 'axios'
import env from '../../enviroinment'
import LoadingSpinner from '../loadingSpinner'
const AdminProfile = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  let token = localStorage.getItem('token')
  let userId = localStorage.getItem('userId')
  let loadData = async () => {
    try {
      setLoading(true);
      let res = await axios.get(`${env.apiurl}/admin/getadminprofile`, {
        headers: { Authorization: `Bearer ${token}`, adminid: userId },
      })
      setLoading(false);
      if (res.data.statusCode === 200) {

        let arr = []
        const {
          adminName,
          email,
          mobile,
          businessName,
          businesslogo,
          QR_code,
          active_flag,
          subscriptionType,
          country,
          currency,
          phonecode
        } = res.data
        let object = {
          adminName: adminName,
          email: email,
          mobile: mobile,
          businessName: businessName,
          businesslogo: businesslogo,
          QR_code: QR_code,
          active_flag: active_flag,
          subscriptionType: subscriptionType,
          country,
          currency,
          phonecode
        }
        arr.push(object)
        setData(arr)
      }
    } catch (error) {
      setLoading(false);
    }

  }
  useEffect(() => {
    loadData()
  }, [])
  return (
    <>
      {loading ? <LoadingSpinner /> : null}
      <div>
        <div className="Main_header">
          <AdminHeader />
          <AdminNav />
        </div>
        <div className="container">
          <div className="frameTitle">
            <h2 className="heading20_bold">Admin Profile</h2>
          </div>
          <div className="ViewPage_frame">
            {data?.map((business, index) => (
              <div key={index} className="row">
                <div className="col-md-2 mobSpace_mb24">
                  <div className="businessLogo">
                    <h5 className="inputTitle mb_12"> Business Logo</h5>
                    <img
                      src={business.businesslogo ? business.businesslogo : null}
                      alt=""
                      width={'200px'}
                      height={'200px'}
                    />
                  </div>
                </div>
                <div className="col-md-7 mobSpace_mb24">
                  <table className="viewDetail_table tableResponsive">
                    <tr>
                      <th>Business Name</th>
                      <td> {business.businessName}</td>
                    </tr>
                    <tr>
                      <th>User Name</th>
                      <td>{business.adminName}</td>
                    </tr>
                    <tr>
                      <th>Phone No</th>
                      <td>{business.phonecode}{business.mobile}</td>
                    </tr>
                    <tr>
                      <th>Country</th>
                      <td>{business.country}</td>
                    </tr>
                    <tr>
                      <th>Currency</th>
                      <td>{business.currency}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{business.email}</td>
                    </tr>
                  </table>
                </div>
                <div className="col-md-3">
                  <h5 className="inputTitle mb_12 textAlign_center">QR-Code</h5>
                  <div className="qrCodeImageFrame">
                    <img
                      src={business.QR_code ? business.QR_code : null}
                      alt=""
                      width={'200px'}
                      height={'200px'}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminProfile
