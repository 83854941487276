import React, { useState } from "react";
import AdminNav from "./AdminNav";
import AdminHeader from "./AdminHeader";
import axios from "axios";
import env from "../../enviroinment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../loadingSpinner";
const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Required"),
      newPassword: Yup.string().required("Required"),
      confirmPassword: Yup.string()
        .required("Confirm Password is required")
        .oneOf(
          [Yup.ref("newPassword"), null],
          "Confirm Password does not match"
        ),
    }),
    onSubmit: async (values) => {
      try {

        let token = localStorage.getItem("token");
        let userId = localStorage.getItem("userId");
        setLoading(true);
        let res = await axios.put(`${env.apiurl}/admin/changePassword`, values, {
          headers: { Authorization: `Bearer ${token}`, adminid: userId },
        });
        setLoading(false);
        if (res.statusCode === 400) {
          toast.error("Incorrect Password", {
            position: toast.POSITION.TOP_RIGHT,

          });
        } else {
          // toast.success("Password Changed Successfully", {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          navigate("/dashboard");
        }
      } catch (error) {
        setLoading(false);
      }

    },
  });
  return (
    <>
      {loading ? <LoadingSpinner /> : null}
      <div>
        <div className="Main_header">
          <AdminHeader />
          <AdminNav />
        </div>
        <div className="displayFlex height_100per alignItem_Center justifyContent_Center">
          <div className="WhiteFrame CenterScreen">
            <h2 className="FrameHeading_28 mb_0 textAlign_center">
              Create New <span className="hightLightThemeClr">Password</span>
            </h2>
            <p className="subDescription_text mb_24 textAlign_center">
              Your new password must be different from previous used password.
            </p>
            <div className="title_border"></div>

            <form onSubmit={formik.handleSubmit}>
              <div className="mb_24">
                <label htmlFor="oldPassword" className="mb_8 inputTitle">
                  Current Password
                </label>
                <input
                  type="password"
                  id="oldPassword"
                  name="oldPassword"
                  placeholder="Old Password"
                  className="MainInput"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.oldPassword}
                />
                {formik.touched.oldPassword && formik.errors.oldPassword ? (
                  <p className="error_text">{formik.errors.oldPassword}</p>
                ) : null}
              </div>
              <div className="mb_16">
                <label htmlFor="newPassword" className="mb_8 inputTitle">
                  New Password
                </label>
                <input
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  placeholder="New Password"
                  className="MainInput"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                  maxLength={15}
                />
                {formik.touched.newPassword && formik.errors.newPassword ? (
                  <p className="error_text">{formik.errors.newPassword}</p>
                ) : null}
              </div>
              <div className="mb_30">
                <label htmlFor="confirmPassword" className="mb_8 inputTitle">
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  className="MainInput"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  maxLength={15}
                />
                {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                  <p className="error_text">{formik.errors.confirmPassword}</p>
                ) : null}
              </div>
              <button type="submit" className="MainButton">
                Change
              </button>
            </form>
          </div>
        </div>
        <ToastContainer autoClose={1800} />
      </div>
    </>
  );
};

export default ChangePassword;
