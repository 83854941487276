import React, { useEffect, useState } from "react";
import AdminNav from "./AdminNav";
import AdminHeader from "./AdminHeader";
import Modal from "react-bootstrap/Modal";
import AddIcon from "../../assets/images/addIcon.svg";
import TemplateIcon from "../../assets/images/template_icon.svg";
import ImportIcon from "../../assets/images/import_icon.svg";
import CloudUploadIcon from "../../assets/images/cloudUpload.svg";
import UploadIcon from "../../assets/images/upload_icon.svg";
import UploadimagCloseIcon from "../../assets/images/uploadImg_closeIcon.svg";
import CloseIcon from "../../assets/images/close_icon.svg";
import { read, utils } from "xlsx";
import env from "../../enviroinment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../loadingSpinner";
import * as XLSX from "xlsx";
import * as Excel from "exceljs";
import * as fs from 'file-saver';
import { useNavigate } from "react-router-dom";

const ProductDataUpload = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let [getCat, setGetCat] = useState([]);
  let vegCat = ['veg', 'nonveg'];

  const [inputValues, setInputValues] = useState([]);
  //let [file, setFile] = useState();
  //const [preview, setPreview] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  let userId = localStorage.getItem("userId");
  let token = localStorage.getItem("token");
  let navigate = useNavigate()

  useEffect(() => {
    if (inputValues.length === 0) {
      setInputValues((inputValues) => [
        ...inputValues,
        {
          id: inputValues.length + 1,
          name: "",
          price: "",
          quantity: "",
          description: "",
          deliveryFee: "",
          category: "",
          cat_id: "",
          sub_category: "",
          sub_categoryList: [],
          skuID: "",
          vegorNonveg: "",
          image: {},
          objectUrl: "",
        },
      ]);
    }
    handleGetCategory();
  }, []);

  const handleClick = () => {
    for (let product of inputValues) {
      if (
        product.name === "" ||
        product.price === "" ||
        product.quantity === "" ||
        product.description === "" ||
        product.category === "" ||
        product.vegorNonveg === "" ||
        product.objectUrl === ""
      ) {
        setFormSubmitted(true);
        return;
      }
    }

    setInputValues((prevProduct) => [
      ...inputValues,
      {
        id: inputValues.length + 1,
        name: "",
        price: "",
        quantity: "",
        description: "",
        deliveryFee: "",
        category: "",
        cat_id: "",
        sub_category: "",
        sub_categoryList: [],
        skuID: "",
        vegorNonveg: "",
        image: {},
        objectUrl: "",
      },
    ]);
    setFormSubmitted(false);
  };
  const excelGenerator = () => {
    let excelHeaders = [
      "Product Name",
      "Price",
      "Available Quatity",
      "Product Category",
      "SkuID",
      "Veg or Non-Veg",
      "Delivery Charge",
      "Product Description",
    ];
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Products Data');
    worksheet.columns = [
      { header: 'productName', key: 'productName', width: 20 },
      { header: 'price', key: 'price', width: 20 },
      { header: 'availableQuantity', key: 'availableQuantity', width: 20 },
      { header: 'prodCategory', key: 'prodCategory', width: 20 },
      { header: 'skuID', key: 'skuID', width: 20 },
      { header: 'vng', key: 'vng', width: 20 },
      { header: 'deliveryCharge', key: 'deliveryCharge', width: 20 },
      { header: 'description', key: 'description', width: 30 },
    ];
    let firstRow = worksheet.getRow(1);
    firstRow.hidden = true

    let titleRow = worksheet.addRow(['Menu']);
    titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
    // Blank Row
    worksheet.addRow([]);
    //Add Header Row
    let headerRow = worksheet.addRow(excelHeaders);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    });
    let category = [];
    getCat.forEach((element) => {
      category.push(element.categoryName)
    })
    let joineddropdownlist = "\"" + category.join(',') + "\"";

    for (let i = 4; i < 100; i++) {
      worksheet.getCell('D' + i).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: [joineddropdownlist]//'"One,Two,Three,Four"'
      };
    }

    let vegdropdownlist = "\"" + vegCat.join(',') + "\"";

    for (let i = 4; i < 100; i++) {
      worksheet.getCell('F' + i).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: [vegdropdownlist]//'"One,Two,Three,Four"'
      };
    }
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'menu uploader Template.xlsx');
    });



















    // let fileName = `menu Details`;
    // let excelHeaders = [
    //   "Product Name",
    //   "Price",
    //   "Available Quatity",
    //   "Product Category",
    //   "Veg or Non-Veg",
    //   "Delivery Charge",
    //   "Product Description",
    // ];
    // let templateToExcel = [excelHeaders, []];
    // const wb = XLSX.utils.book_new();
    // const ws = XLSX.utils.json_to_sheet([]);
    // XLSX.utils.sheet_add_aoa(ws, templateToExcel);
    // ws["!cols"] = [
    //   { width: 20 },
    //   { width: 15 },
    //   { width: 15 },
    //   { width: 15 },
    //   { width: 15 },
    //   { width: 15 },
    //   { width: 25 },
    // ];
    // // Add Data and Conditional Formatting
    // let joineddropdownlist = '"' + getCat.join(",") + '"';
    // 
    // for (let i = 4; i < 100; i++) {
    //   wb.getCell("C" + i).dataValidation = {
    //     type: "list",
    //     allowBlank: true,
    //     formulae: [joineddropdownlist], //'"One,Two,Three,Four"'
    //   };
    // }

    // // ws.map((cell, number) => {
    // //   cell.fill = {
    // //   type: 'pattern',
    // //   pattern: 'solid',
    // //   fgColor: { argb: 'FFFFFF00' },
    // //   bgColor: { argb: 'FF0000FF' }
    // //   }
    // // })
    // //XLSX.utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
    // XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    // XLSX.writeFile(wb, `${fileName}.xlsx`);
  };
  // const onButtonClick = () => {
  //   // using Java Script method to get PDF file
  //   fetch("sayshopMenuUpload.xlsx").then((response) => {
  //     response.blob().then((blob) => {
  //       // Creating new object of PDF file
  //       const fileURL = window.URL.createObjectURL(blob);
  //       // Setting various property values
  //       let alink = document.createElement("a");
  //       alink.href = fileURL;
  //       alink.download = "sayshopMenuUpload.xlsx";
  //       alink.click();
  //     });
  //   });
  // };
  let handleGetCategory = async () => {
    try {
      let res = await axios.get(`${env.apiurl}/foods/get-category`, {
        headers: { Authorization: `Bearer ${token} `, adminid: userId },
      });
      if (res.data.statusCode === 200) {
        let category = [];


        // res.data.data.forEach((element) => {
        //   category.push(element);
        // });

        setGetCat(res.data.data);
      } else {
        // toast.error(res.data.message);

      }
    } catch (error) {
      console.log(error);
    }
  };
  //getting subcategory
  let handleGetSubCat = async (value, index) => {

    //let category= getCat.find((element)=>(value===element.categoryName))

    try {
      let res = await axios.get(
        `${env.apiurl}/foods/get-subcategory/${value}`,
        {
          headers: { Authorization: `Bearer ${token} `, adminid: userId },
        }
      );

      if (res.data.statusCode === 200) {

        // let subCategory=[]
        // res.data.data.forEach((element)=>{
        //   subCategory.push(element)
        // })
        let sub_categoryList = res.data.data;
        setInputValues((prevValues) =>
          prevValues.map((x) =>
            x.id === index ? {
              ...x,
              sub_categoryList,
            }
              : x
          )
        )

        // setGetSub(res.data.data)

      } else {
        // setGetSub([])
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleImport = (event) => {
    const files = event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);

          let arrayData = [];
          rows.slice(2).map((product, index) => {
            let individualItem = {
              id: arrayData.length + 1,
              name: "",
              price: "",
              quantity: "",
              description: "",
              deliveryFee: "",
              category: "",
              cat_id: "",
              sub_category: "",
              sub_categoryList: [],
              skuID: "",
              vegorNonveg: "",
              image: {},
              objectUrl: "",
            };
            Object.keys(product).forEach((key) => {
              if (key === "productName") {
                individualItem.name =
                  product[key] !== undefined ? product[key] : "";
              } else if (key === "price") {
                individualItem.price =
                  product[key] !== undefined ? product[key] : "";
              } else if (key === "availableQuantity") {
                individualItem.quantity =
                  product[key] !== undefined ? product[key] : "";
              } else if (key === "prodCategory") {
                individualItem.category =
                  product[key] !== undefined ? product[key] : "";
              } else if (key === "skuID") {
                individualItem.skuID =
                  product[key] !== undefined ? product[key] : "";
              } else if (key === "vng") {
                individualItem.vegorNonveg =
                  product[key] !== undefined ? product[key] : "";
              } else if (key === "deliveryCharge") {
                individualItem.deliveryFee =
                  product[key] !== undefined ? product[key] : "";
              } else if (key === "description") {
                individualItem.description =
                  product[key] !== undefined ? product[key] : "";
              }
            });
            if (individualItem.category != "")
              handleGetSubCat(individualItem.category, arrayData.length + 1)
            arrayData.push(individualItem);

          });
          setInputValues([...arrayData]);

          // setMovies(rows)
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };
  const handleSubmit = async () => {
    console.log("inputValues", inputValues);
    try {
      setFormSubmitted(true);
      for (let product of inputValues) {
        if (
          product.name === "" ||
          product.price === "" ||
          product.quantity === "" ||
          product.description === "" ||
          product.category === "" ||
          product.vegorNonveg === "" ||
          product.skuID === "" ||
          product.objectUrl === ""
        ) {
          return;
        }
      }

      let formData = new FormData();

      for (let product of inputValues) {
        formData.append("name", product.name);
        formData.append("price", product.price);
        formData.append("description", product.description);
        formData.append("deliveryFee", product.deliveryFee);
        formData.append("quantity", product.quantity);
        formData.append("category", product.category);
        formData.append("subCategory", product.sub_category);
        formData.append("skuID", product.skuID);
        formData.append("vegorNonveg", product.vegorNonveg);

        formData.append("file", product.image);
      }
      formData.append("length", inputValues.length);
      setLoading(true);
      let data = await axios.post(
        `${env.apiurl}/foods/add-bulk-food`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}`, adminid: userId },
        }
      );
      setLoading(false);


      if (data.data.statusCode === 200) {
        toast.success(data.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/dashboard")

      } else if (data.data.statusCode === 400) {
        toast.error(data.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };
  let Val = 0;
  // let handleMinValue = (deliveryFee, product)=>{

  //   deliveryFee<0?setInputValues((prevValues) =>

  //   prevValues.map((x) =>

  //     x.id === product.id
  //       ? {
  //         ...x,
  //         Val

  //       }
  //       : x
  //   )
  // ):

  //   setInputValues((prevValues) =>
  //   prevValues.map((x) =>
  //     x.id === product.id
  //       ? {
  //         ...x,
  //         deliveryFee,
  //       }
  //       : x
  //   )
  // );
  // }

  return (
    <>
      {loading ? <LoadingSpinner /> : null}
      <div>
        <div className="Main_header">
          <AdminHeader />
          <AdminNav />
        </div>

        <div className="container">
          <div className="pageInfo flexWrap_wrap">
            <div>
              <h2 className="heading24_bold mobSpace_pb16">Product Upload</h2>
            </div>
            <div className="displayFlex alignItem_Center gap_8 flexWrap_wrap">
              <div>
                <button
                  className="commonButton UFOGreenColor"
                  onClick={handleClick}
                >
                  <img src={AddIcon} alt="AddIcon" className="mr_8" />
                  <span>Add Row</span>
                </button>
              </div>
              <div>
                <button
                  className="commonButton CarbonFootprintColor"
                  onClick={excelGenerator}
                >
                  <img src={TemplateIcon} alt="TemplateIcon" className="mr_8" />
                  <span>Sample Template</span>
                </button>
              </div>
              <div>
                {/* <button
                className="commonButton RoyalGreenColor"
                htmlFor="ChooseAnexcel"
              >
                <img src={ImportIcon} alt="" className="mr_8" />
                <span>Import</span>
                <input
                  type="file"
                  name="importFile"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleImport}
                  id="ChooseAnexcel"
                  hidden
                />
              </button> */}
                <label
                  htmlFor="ChooseAnImage"
                  className="commonButton RoyalGreenColor"
                >
                  <img src={ImportIcon} alt="importIcon" className="mr_8" />
                  <span>Import</span>
                  <input
                    type="file"
                    name="image"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={handleImport}
                    id="ChooseAnImage"
                    hidden
                    required
                  />
                </label>
              </div>
              <div>
                <button
                  className="commonButton RoyalFlycatcherCrestColor"
                  onClick={handleSubmit}
                >
                  <img src={CloudUploadIcon} alt="" className="mr_8" />
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
          <div className="TableWrapper tableResponsive">
            <table>
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Price of Product</th>
                  <th>Available Quantity</th>
                  <th>Product Category</th>
                  <th>Sub-Category</th>
                  <th>skuID</th>
                  <th>Veg or Non-Veg</th>
                  <th>Delivery Charge</th>
                  <th>Product Description</th>
                  <th>Upload Image</th>
                </tr>
              </thead>
              <tbody>
                {inputValues.length > 0
                  ? inputValues.map((product, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            value={product.name}
                            className="MainInput"
                            placeholder="Enter Product Name"
                            onChange={(e) => {
                              let name = e.target.value;
                              setInputValues((prevValues) =>
                                prevValues.map((x) =>
                                  x.id === product.id
                                    ? {
                                      ...x,
                                      name,
                                    }
                                    : x
                                )
                              );

                            }}
                          // value={values.name}
                          />
                          {product.name === "" && formSubmitted ? (
                            <div className="invalidResponse">
                              *This is mandatory
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <input
                            type="number"
                            id="price"
                            name="price"
                            value={product.price}
                            className="MainInput"
                            placeholder="Enter product Price"
                            onChange={(e) => {
                              let price = e.target.value;

                              price < 0
                                ? setInputValues((prevValues) =>
                                  prevValues.map((x) =>
                                    x.id === product.id
                                      ? {
                                        ...x,
                                        Val,
                                      }
                                      : x
                                  )
                                )
                                : setInputValues((prevValues) =>
                                  prevValues.map((x) =>
                                    x.id === product.id
                                      ? {
                                        ...x,
                                        price,
                                      }
                                      : x
                                  )
                                );
                            }}

                          // setInputValues((prevValues) =>
                          //   prevValues.map((x) =>
                          //     x.id === product.id
                          //       ? {
                          //         ...x,
                          //         price,
                          //       }
                          //       : x
                          //   )
                          // );

                          // value={values.price}
                          />
                          {product.price === "" && formSubmitted ? (
                            <div className="invalidResponse">
                              *This is mandatory
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="MainInput"
                            id="quantity"
                            name="quantity"
                            value={product.quantity}
                            placeholder="Available Quantity"
                            onChange={(e) => {
                              let quantity = e.target.value;
                              setInputValues((prevValues) =>
                                prevValues.map((x) =>
                                  x.id === product.id
                                    ? {
                                      ...x,
                                      quantity,
                                    }
                                    : x
                                )
                              );
                            }}

                          // value={values.quantity}
                          />
                          {product.quantity === "" && formSubmitted ? (
                            <div className="invalidResponse">
                              *This is mandatory
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <div className="displayFlex gap_8">
                            <div>
                              <select
                                value={product.category}
                                onChange={(e) => {
                                  let category = e.target.value;
                                  handleGetSubCat(e.target.value, product.id)
                                  category < 0
                                    ? setInputValues((prevValues) =>
                                      prevValues.map((x) =>
                                        x.id === product.id
                                          ? {
                                            ...x,
                                            Val,
                                          }
                                          : x
                                      )
                                    )
                                    : setInputValues((prevValues) =>
                                      prevValues.map((x) =>
                                        x.id === product.id
                                          ? {
                                            ...x,
                                            category,
                                          }
                                          : x
                                      )
                                    );
                                }}
                                className="MainInput"
                                name="category"
                              >

                                <option>Select Category</option>
                                {getCat.map((cat) => (
                                  <option value={cat.categoryName} key={cat._id}>
                                    {cat.categoryName}
                                  </option>
                                ))}

                              </select>
                              {product.category === "" && formSubmitted ? (
                                <div className="invalidResponse">
                                  *This is mandatory
                                </div>
                              ) : null}
                            </div>
                            {/* <div>
                            <button onClick={handleShow} className="smallbtn">
                              <img src={AddIcon} />
                            </button>
                          </div> */}
                          </div>

                          {/* add category popup start */}

                          {/* <Modal
                          show={show}
                          onHide={handleClose}
                          className="addsmallpopup"
                        >
                          <Modal.Header>
                            <div>
                              <h4>Add Category</h4>
                            </div>
                            <div>
                              <button
                                className="emptyBtn"
                                onClick={handleClose}
                              >
                                <img src={CloseIcon} />
                              </button>
                            </div>
                          </Modal.Header>
                          <div className="modelScroll">
                            <Modal.Body>
                              <div className="mb_24">
                                <label className="grayUpperLabel">
                                  Category Name
                                </label>
                                <input
                                  type="number"
                                  className="MainInput"
                                  placeholder="Enter category Name"
                                  value=""
                                />
                              </div>
                              <div>
                                <button className="commonButton RoyalFlycatcherCrestColor width_80 margin_auto displayBlock">
                                  Submit
                                </button>
                              </div>
                            </Modal.Body>
                          </div>
                        </Modal> */}

                          {/* add category popup end */}
                        </td>
                        <td>
                          <div className="displayFlex gap_8">
                            <div>
                              <select
                                value={product.sub_category}
                                onChange={(e) => {
                                  let sub_category = e.target.value;
                                  sub_category < 0
                                    ? setInputValues((prevValues) =>
                                      prevValues.map((x) =>
                                        x.id === product.id
                                          ? {
                                            ...x,
                                            Val,
                                          }
                                          : x
                                      )
                                    )
                                    : setInputValues((prevValues) =>
                                      prevValues.map((x) =>
                                        x.id === product.id
                                          ? {
                                            ...x,
                                            sub_category,
                                          }
                                          : x
                                      )
                                    );
                                }}
                                className="MainInput"
                                name="sub_category"
                              >

                                <option>Select Category</option>
                                {product.sub_categoryList.map((cat) => (
                                  <option value={cat.subcategory} key={cat._id}>
                                    {cat.subcategory}
                                  </option>
                                ))}

                              </select>

                            </div>
                          </div>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="MainInput"
                            id="skuID"
                            name="skuID"
                            value={product.skuID}
                            placeholder="skuID"
                            onChange={(e) => {
                              let skuID = e.target.value;
                              setInputValues((prevValues) =>
                                prevValues.map((x) =>
                                  x.id === product.id
                                    ? {
                                      ...x,
                                      skuID,
                                    }
                                    : x
                                )
                              );
                            }}

                          // value={values.quantity}
                          />
                          {product.skuID === "" && formSubmitted ? (
                            <div className="invalidResponse">
                              *This is mandatory
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <select
                            onChange={(e) => {
                              let vegorNonveg = e.target.value;
                              setInputValues((prevValues) =>
                                prevValues.map((x) =>
                                  x.id === product.id
                                    ? {
                                      ...x,
                                      vegorNonveg,
                                    }
                                    : x
                                )
                              );
                            }}
                            className="MainInput"
                            name="vegorNonveg"
                            value={product.vegorNonveg}
                            required
                          >
                            <option>Select Veg/Non-Veg</option>
                            <option value="veg">Veg</option>
                            <option value="nonveg">Non-Veg</option>
                          </select>
                          {product.vegorNonveg === "" && formSubmitted ? (
                            <div className="invalidResponse">
                              *This is mandatory
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="MainInput"
                            id="deliveryFee"
                            name="deliveryFee"
                            min="0"
                            placeholder="Delivery Charge"
                            value={product.deliveryFee}
                            onChange={(e) => {
                              let deliveryFee = e.target.value;

                              deliveryFee < 0
                                ? setInputValues((prevValues) =>
                                  prevValues.map((x) =>
                                    x.id === product.id
                                      ? {
                                        ...x,
                                        Val,
                                      }
                                      : x
                                  )
                                )
                                : setInputValues((prevValues) =>
                                  prevValues.map((x) =>
                                    x.id === product.id
                                      ? {
                                        ...x,
                                        deliveryFee,
                                      }
                                      : x
                                  )
                                );
                            }}
                            required
                          // value={values.quantity}
                          />
                          {product.deliveryFee === "" && formSubmitted ? (
                            <div className="invalidResponse">
                              *This is mandatory
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <textarea
                            className="MainInput"
                            name="description"
                            id="description"
                            value={product.description}
                            placeholder="Description"
                            onChange={(e) => {
                              let description = e.target.value;

                              setInputValues((prevValues) =>
                                prevValues.map((x) =>
                                  x.id === product.id
                                    ? {
                                      ...x,
                                      description,
                                    }
                                    : x
                                )
                              );
                            }}
                            required
                          ></textarea>
                          {product.description === "" && formSubmitted ? (
                            <div className="invalidResponse">
                              *This is mandatory
                            </div>
                          ) : null}
                        </td>
                        <td>
                          {product.objectUrl === "" ? (
                            <label
                              htmlFor="ChooseAnProductImage"
                              className="ImagePanel whiteSpace_nowrap"
                            >
                              <img className="mr_12" src={UploadIcon} />

                              <span>Choose Images</span>
                              <input
                                type="file"
                                name="image"
                                accept="image/png, image/jpg, image/jpeg, image/gif"
                                onChange={(e) => {
                                  let image = e.target.files[0];
                                  let objectUrl = URL.createObjectURL(image);
                                  setInputValues((prevValues) =>
                                    prevValues.map((x) =>
                                      x.id === product.id
                                        ? {
                                          ...x,
                                          image,
                                          objectUrl,
                                        }
                                        : x
                                    )
                                  );
                                }}
                                id="ChooseAnProductImage"
                                hidden
                              />
                            </label>
                          ) : null}

                          {product.objectUrl !== "" ? (
                            <div className="image_parent">
                              <div className="imagePlaceholder">
                                <a
                                  href="#"
                                  className="closeIconforUpload"
                                  onClick={(e) => {
                                    let image = {};
                                    let objectUrl = "";
                                    setInputValues((prevValues) =>
                                      prevValues.map((x) =>
                                        x.id === product.id
                                          ? {
                                            ...x,
                                            image,
                                            objectUrl,
                                          }
                                          : x
                                      )
                                    );
                                  }}
                                >
                                  <img src={UploadimagCloseIcon} />
                                </a>
                                <img
                                  className="upload_img"
                                  src={product.objectUrl}
                                  alt="upload_img"
                                />
                              </div>
                            </div>
                          ) : null}
                          {/* {product.objectUrl === "" && formSubmitted ? (
                          <div className="invalidResponse">
                            *This is mandatory
                          </div>
                        ) : null} */}
                        </td>
                      </tr>
                    );
                  })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        <ToastContainer autoClose={1800} />
      </div>
    </>
  );
};
export default ProductDataUpload;
