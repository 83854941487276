import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartContext } from "../../App";
import LoadingSpinner from '../loadingSpinner';

const MyIframe = ({ url }) => {

    const navigate = useNavigate();
    const context = useContext(CartContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.addEventListener('message', handleMessage);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    // Function to handle messages received from the iframe
    const handleMessage = (event) => {
        // Check if the message is from the iframe
        // debugger
        // if (event.origin !== url) return;

        // Check the content of the message
        if (event.data === 'closeWidget') {
            // Close the widget or perform any other action
            closeWidget();
        }
        if (event.data === 'back') {
            // Close the widget or perform any other action
            back();
        }
    };

    useEffect(() => {
        // Add class on mount
        const element = document.querySelector('.my-element');
        if (element) {
            element.classList.add('p0');
        }

        // Remove class on unmount
        return () => {
            if (element) {
                element.classList.remove('p0');
            }
        };
    }, []);

    // Function to close the widget
    const closeWidget = () => {
        // Close or hide the widget
        context.setCart([]);
        navigate("/tracking");
    };
    const back = () => {
        navigate("/payment");
    };
    // Function to handle iframe load
    const handleIframeLoad = () => {
        setIsLoading(false);
    };

    return (
        <>
            {isLoading ? <LoadingSpinner /> : null}
            <iframe
                title="My Iframe"
                src={url}
                onLoad={handleIframeLoad}
                className='iframeauto'
            />
        </>

    );
};

export default MyIframe;
