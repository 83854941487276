import { useNavigate } from 'react-router-dom'
import VerifyTick from "../../../../assets/images/failure_tick.png";
import { useParams } from "react-router-dom";
import axios from 'axios';
import env from '../../../../enviroinment';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../../../loadingSpinner';

export const Failure = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const { id } = useParams();
  const [isOrderExists, setOrderExists] = useState(false)

  const businessName = localStorage.getItem('businessName')

  useEffect(() => {
    paymentFailed()
  }, [])

  const paymentFailed = async () => {
    setLoading(true);
    await axios.get(`${env.apiurl}/payments/${id}`).then((res) => {
      setLoading(false);
      if (res.data.statusCode === 200) {
        setOrderExists(true)
        setTimeout(() => {
          navigate('/checkout')
        }, 2500)
      } else {
        navigate(`/user-menu/${businessName}`)
      }
    }).catch((error) => {
      setLoading(false);
    })
  }

  return (
    <>
      {loading ? <LoadingSpinner /> : null}
      <div className="height_100per">
        {isOrderExists ? <div className="displayFlex height_100per alignItem_Center justifyContent_Center">
          <div className="WhiteFrame CenterScreen textAlign_center borderRadius_8  height_360 displayFlex alignItem_Center justifyContent_Center flexDirectionColumn">
            <img className="mb_24 width_120" src={VerifyTick} />
            <h2 className="heading28px_bold textAlign_center">
              Try Again...
            </h2>
            <p className="subDescription_text textAlign_center">
              Payment Failed
            </p>
          </div>
        </div> : <></>}

      </div>
    </>
  );
}