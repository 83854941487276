import './App.css'
import './style.scss'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import ProductDashboard from './components/admin/ProductDashboard'
import Orders from './components/admin/Orders'
import AdminUsers from './components/admin/AdminUsers'
import ChangePassword from './components/admin/ChangePassword'
// import OrderItem from "./components/admin/OrderItem";
import Food from './components/users/Food'
import Checkout from './components/users/Checkout'
import Login from './components/users/Login'
import Signup from './components/users/SignUp'
// import Success from "./components/users/Success";
import Tracking from './components/users/Tracking'
import OrderMessage from './components/users/OrderMessage'
import AdminProfile from './components/admin/AdminProfile'
import Payment from './components/users/Payment'
import PaymentDetails from './components/admin/PaymentDetails'
import UpiPayment from './components/users/UpiPayment'
import ProductDataUpload from "./components/admin/ProductDataUpload";
import { Success as StripePaymentSuccess } from "./components/users/payment/stripe/success";
import { Failure as StripePaymentFailure } from "./components/users/payment/stripe/failure";

import React, { useState } from 'react'
import ListAllOrders from './components/users/tracking/list-all-orders'
import ProtectedRoutes from './components/auth/protectedRoute'
import UserProfile from './components/users/UserProfile'
import AuthVerify from './common_var/auth-verify'
import Category from './components/admin/Category'
import SubCategory from './components/admin/SubCategory'
import KrtiPay from './components/krti-pay/KrtiPay'
export const CartContext = React.createContext()
export const userContext = React.createContext()

function App() {
  let [cart, setCart] = useState([])
  let [isLoggedIn, setloggedIn] = useState(localStorage.getItem('token'))


  return (
    <>
      <BrowserRouter>
        <userContext.Provider value={{ isLoggedIn, setloggedIn }}>
          <CartContext.Provider value={{ cart, setCart }}>
            <Routes>
              <Route path="/dashboard" element={<ProtectedRoutes isLoggedIn={isLoggedIn} >
                <ProductDashboard />
              </ProtectedRoutes>
              } />
              <Route path="/category" element={<ProtectedRoutes isLoggedIn={isLoggedIn}><Category />
              </ProtectedRoutes>} />


              <Route path="/subcategory/:id" element={<ProtectedRoutes isLoggedIn={isLoggedIn}><SubCategory />
              </ProtectedRoutes>} />

              <Route path="/order" element={<ProtectedRoutes isLoggedIn={isLoggedIn} >
                <Orders />
              </ProtectedRoutes>} />
              <Route path="/product-upload" element={<ProtectedRoutes isLoggedIn={isLoggedIn} >
                <ProductDataUpload />
              </ProtectedRoutes>} />

              <Route path="/business-upi" element={<ProtectedRoutes isLoggedIn={isLoggedIn} >
                <PaymentDetails />
              </ProtectedRoutes>} />
              <Route path="/admin-users" element={<ProtectedRoutes isLoggedIn={isLoggedIn} >
                <AdminUsers />
              </ProtectedRoutes>} />
              <Route path="/change-password" element={<ProtectedRoutes isLoggedIn={isLoggedIn} >
                <ChangePassword />
              </ProtectedRoutes>} />
              {/* <Route path="/dashboard/:id" element={<OrderItem />} /> */}
              <Route path="/user-menu/:businessName" element={<Food />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/login" element={isLoggedIn ? <Navigate to="/dashboard" replace /> : <Login />} />
              <Route path="/signup" element={isLoggedIn ? <Navigate to="/dashboard" replace /> : <Signup />} />
              <Route path="/upi" element={isLoggedIn ? <Navigate to="/dashboard" replace /> : <Payment />} />
              <Route path="/payment" element={<UpiPayment />} />
              <Route path="/krti" element={<KrtiPay />} />
              <Route path="/payment/stripe/success/:id" element={<StripePaymentSuccess />} />
              <Route path="/payment/stripe/failure/:id" element={<StripePaymentFailure />} />
              <Route path="/order-success" element={<OrderMessage />} />
              <Route path="/tracking" element={<Tracking />} />
              <Route path='/tracking/all-orders' element={<ListAllOrders />} />
              <Route path='/user-profile' element={<UserProfile />} />
              <Route path="/admin-profile" element={<ProtectedRoutes isLoggedIn={isLoggedIn} >
                <AdminProfile />
              </ProtectedRoutes>} />
              <Route path="*" element={<Navigate to="/user-menu/theecode" />} />
              {/* <Route path="/order-message" element={<OrderMessage />} /> */}
            </Routes>
            <AuthVerify setloggedIn={setloggedIn} />
          </CartContext.Provider>
        </userContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App
