import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import RupeeIcon from "../../assets/images/bx_rupee_icon.svg";
import BorderAfterBefore from "../../assets/images/borerTopBottoma.svg";
import BackarrowIcon from "../../assets/images/backarrow_icon.svg";
import upiAppIcon from "../../assets/images/upiApp_icon.svg";
import GooglepayIcon from "../../assets/images/gpay_icon.svg";
import PhonepayIcon from "../../assets/images/phonepay_icon.svg";
import WhatsappIcon from "../../assets/images/whatsapp_icon.svg";
import PaytmIcon from "../../assets/images/paytm_icon.svg";
import UserprofileIcon from "../../assets/images/user_db.svg";
import PayonDeliveryIcon from "../../assets/images/payonDelivery_icon.svg";
import krtilogo from "../../assets/images/krti-logo.png";
import env from "../../enviroinment";
import UserHeader from "./UserHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { CartContext } from "../../App";
import LoadingSpinner from "../loadingSpinner";
import { authMerchant } from "../../services/krti.service";
import MyIframe from "../krti-pay/iframe";

const UpiPayment = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [logo, setLogo] = useState("");
  const [total, setTotal] = useState(0);
  let formattedPrice = total.toFixed(3);
  const [upi, setupi] = useState("");
  const [upiName, setupiName] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileCode, setMobileCode] = useState("");
  const [paymentType, setPaymentType] = useState(null);
  const context = useContext(CartContext);
  const location = useLocation();
  let token = localStorage.getItem("token");
  let userId = localStorage.getItem("userId");
  let orderId = localStorage.getItem("orderId");
  const businessName = localStorage.getItem("businessName");
  const customerId = localStorage.getItem("CustomerId");
  let loadData = async () => {
    try {
      setLoading(true);
      let res = await axios.get(`${env.apiurl}/admin/getpaymentqr`, {
        headers: { Authorization: `Bearer ${token}`, adminid: userId },
      });
      setLoading(false);
      if (res.data.statusCode === 200) {
        setupi(res.data.paymentUpi);
        setupiName(res.data.upiName);
        let arr = [];
        const { paymentUpi, upiMobile, businessQr, upiName } = res.data;
        let object = {
          paymentUpi: paymentUpi,
          upiMobile: upiMobile,
          businessQr: businessQr,
          upiName: upiName,
        };
        arr.push(object);
        setData(arr);
      } else {
      }
    } catch (error) {
      setLoading(false);
    }
  };
  let loadBusinessLogo = async () => {
    try {
      setLoading(true);
      let res = await axios.get(`${env.apiurl}/admin/getadminlogo`, {
        headers: { adminid: userId },
      });
      setLoading(false);
      if (res.data.statusCode === 200) {
        let array = [];
        const { businesslogo } = res.data;
        let object = {
          businesslogo: businesslogo,
        };
        array.push(object);
        setLogo(array);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadData();
    loadBusinessLogo();
    setMobile(localStorage.getItem('krti_mobile') || "");
    setMobileCode(localStorage.getItem('mobile_code')?.replace("+", '') || "")
  }, []);
  useEffect(() => {
    checkCart(context.cart);
    let total = 0;
    context.cart.map((item) => {
      total += item.selected_quantity_price + item.deliveryFee;
    });
    setTotal(total);

    localStorage.setItem('cartTotal', total)

  }, [context.cart]);
  const checkCart = (arr) => {
    let nav = arr.length <= 0 ? navigate("/user-menu") : null;
    return nav;
  };
  const paymentMode = async () => {
    setLoading(true);
    await axios
      .put(`${env.apiurl}/payments/paymentmode/${orderId}`, {
        headers: { adminid: userId },
      })
      .then((res) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const paybyUpi = () => {
    window.open(
      `upi://pay?pa=${upi}&pn=${upiName}&am=1&cu=INR&mc=0000&mode=02&purpose=00`
    );
    setTimeout(() => {
      alert("Please Wait until we Redirect you to the Tracking");
    }, 10000);
    setTimeout(() => {
      context.setCart([]);
      paymentMode();
    }, 15000);
  };

  const payUsingStripe = () => {
    setLoading(true);
    axios
      .get(`${env.apiurl}/orders/${orderId}`, {
        headers: { Authorization: `Bearer ${token}`, adminid: userId },
      })
      .then(({ data: { orderItems }, data: { _id: id } }) => {
        setLoading(true);
        axios
          .post(`${env.apiurl}/payments/stripe-payment`, { orderItems, id })
          .then((res) => {
            setLoading(false);
            window.location.href = res.data.url;
          })
          .catch((error) => {
            setLoading(false);
          });
      })
      .catch((error) => {
        setLoading(false);
      });

    paymentMode();
  };



  // fixed header start
  const [fix, setFix] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setFix(true);
      } else if (window.scrollY === 0) {
        setFix(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleBckArrowIcon = () => {
    navigate("/checkout", { state: { from: location } });
  };
  let currencySymbol = localStorage.getItem("currencySymbol");




  const krtiPay = async () => {
    try {
      const req = {
        amount: total,
        mobile: `${mobileCode}${mobile}`,
        mobile_code: ''
      }
      const res = await authMerchant(req);
      if (res?.data?.statusCode === 200) {
        let url = res?.data?.data?.url;
        localStorage.setItem('K-token', res?.data?.data?.token)
        navigate('/krti', { state: { url } });
      } else {
        // toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        // alert(msg);
        console.error(msg)
      }
      console.log(error.message);

    }

  }
  const paybyCash = () => {
    context.setCart([]);
    navigate("/tracking");
  };
  const handlePaynow = async () => {
    if (!paymentType) return null;

    if (paymentType === 'krti') {
      await krtiPay();
    } else {
      await paybyCash();
    }
  };

  return (
    <>
      <div className="bgcolor">
        {loading ? <LoadingSpinner /> : null}
        {

        }
        <div className="width_500 marginleftRight_auto whiteBg brradius10 h100vh">
          <div className={fix ? "sticky_top" : "mobiletopHeader mb_16"}>
            <div className="container">
              <div className="displayFlex alignItem_Center justifyContent_spaceBetween">
                <div onClick={handleBckArrowIcon}>
                  <a style={{ cursor: "pointer" }}>
                    <img src={BackarrowIcon} />
                  </a>
                </div>
                <div>
                  <h5 className="blackgraytext textAlign_center">
                    Payment Options
                  </h5>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div className="container">
            {/* Remove the inline style to add the upi payment back */}
            <h2 style={{ display: "none" }} className="blackgraytext mb_16">
              UPI
            </h2>
            <div
              style={{ display: "none" }}
              className="PaymentOptions_item mb_30"
            >
              {data?.map((upi, index) => (
                <div key={index} className="row">
                  {/* <div className="col-md-4 mobSpace_mb24">
                <div className="deskTopFlex_mobileBlock alignItem_Center justifyContent_spaceBetween">
                  <div className="qrCodeImageFrame">
                    <img src={upi.businessQr ? upi.businessQr : null} />
                  </div>
                </div>
              </div> */}
                  {/* <div className="col-md-4 mobSpace_mb24 displayFlex alignItem_Center justifyContent_Center flexDirectionColumn"></div> */}
                </div>
              ))}
              <div className="displayFlex alignItem_Center mb_16">
                <div className="paymentoptionImg mr_12">
                  {logo &&
                    logo.map((item, index) => (
                      <img
                        key={index}
                        src={item.businesslogo ? item.businesslogo : null}
                        alt=""
                      />
                    ))}
                  {/* <img src={UserprofileIcon} /> */}
                </div>
                <div>
                  <h5>{upi}</h5>
                </div>
              </div>
              <a
                className="GreenButton margin_auto borderRadius_99 width_230"
                onClick={paybyUpi}
                style={{ cursor: "pointor" }}
              >
                Pay {/*<img src={RupeeIcon} /> */}
                <span className="mr_4">
                  {currencySymbol} {total}
                </span>{" "}
                with UPI
              </a>
              <div className="dashedBorderBottom mt_24"></div>

              <h3 className="textAlign_center ">Pay using any UPI App</h3>

              <ul className="Payupi_App">
                <li>
                  <a>
                    <img src={upiAppIcon} />
                  </a>
                </li>
                <li>
                  <a>
                    <img src={GooglepayIcon} />
                  </a>
                </li>
                <li>
                  <a>
                    <img src={PhonepayIcon} />
                  </a>
                </li>
                <li>
                  <a>
                    <img src={WhatsappIcon} />
                  </a>
                </li>
                <li>
                  <a>
                    <img src={PaytmIcon} />
                  </a>
                </li>
              </ul>
            </div>


            {/* <h2 className="blackgraytext mb_16">Krti wallet</h2> */}
            <div onClick={() => setPaymentType('krti')} className="paymentBlock mb_16 mt_34">
              <div>

                <img src={krtilogo} />

              </div>
              <div>

                <input type="radio" checked={paymentType === 'krti'} />

              </div>


            </div>


            <div onClick={() => setPaymentType('cod')} className="paymentBlock mb_16">
              <div>

                Cash on Delivery

              </div>
              <div>

                <input type="radio" checked={paymentType === 'cod'} />

              </div>


            </div>



            <div className="paymentSummaryBlock">

              <div className="fs20 pb_50" > Payment Summary</div>
              <div className="summaryBlock mb_16">
                <div>Total</div>
                <div>{currencySymbol} {formattedPrice}</div>
              </div>



              <button
                className="paymentButton"
                disabled={!paymentType}
                onClick={handlePaynow}
              >
                Pay Now
              </button>

            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default UpiPayment;



//  <div className="PaymentOptions_item">
{/* <div className="displayFlex alignItem_Center  mb_16">
<div className="paymentoptionImg mr_12">
  <img src={PayonDeliveryIcon} />
</div>
<div>
  <h5>Krti </h5>
</div>
</div>

<button
className="GreenButton margin_auto borderRadius_99 width_230"
onClick={krtiPay}
>
Pay {currencySymbol} 
<span className="mr_4"> {total}</span> with krti
</button>
</div>

<h2 className="blackgraytext mb_16">Pay On Delivery</h2>
<div className="PaymentOptions_item">
<div className="displayFlex alignItem_Center  mb_16">
<div className="paymentoptionImg mr_12">
  <img src={PayonDeliveryIcon} />
</div>
<div>
  <h5>Cash on Delivery</h5>
</div>
</div>

<button
className="GreenButton margin_auto borderRadius_99 width_230"
onClick={paybyCash}
>
Pay {currencySymbol} 
<span className="mr_4"> {total}</span> with Cash
</button>
</div> */}