import React, { useContext, useState } from "react";
import axios from "axios";
import env from "../../enviroinment";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import AdminloginBG from "../../assets/images/foodTime.jpg";
import * as Yup from "yup";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Mainlogo from "../../assets/images/logo.svg";
import { userContext } from "../../App";
import LoadingSpinner from "../loadingSpinner";
import { Post } from '../../common_var/httpService'
import EyeshowIcon from "../../assets/images/eye_show.svg";
import EyehideIcon from "../../assets/images/eye_hide.svg";


const Login = () => {
  const [loading, setLoading] = useState(false);
  const context = useContext(userContext);
  const [type, setType] = useState(true)
  let navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          'Field should contain a valid e-mail id'
        )
        .max(255)
        .required('E-mail is required'),
      password: Yup.string().required('Passwor is required').min(6),
    }),
    onSubmit: async (values) => {

      try {
        let res = await Post(`admin/login`, values)
        console.log("testing ", res)


        if (res.role === 'StoreAdmin') {
          setLoading(false);
          localStorage.setItem('token', res.token);
          localStorage.setItem('role', res.role);
          localStorage.setItem('userId', res.userId);
          localStorage.setItem('phonecode', res.phonecode);
          localStorage.setItem('currency', res.currency);
          localStorage.setItem('symbol', res.currencySymbol);
          context.setloggedIn(localStorage.getItem('token'))
          navigate('/dashboard');
        } else {
          toast.error(res.message);
        }

      } catch (error) {
        console.log("error", error)
      }
      setLoading(true);
      axios
        .post(`${env.apiurl}/admin/login`, values)
        .then((res) => {

          setLoading(false);
          if (res.data.role === 'StoreAdmin') {
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('role', res.data.role);
            localStorage.setItem('userId', res.data.userId);
            localStorage.setItem('phonecode', res.data.phonecode);
            localStorage.setItem('currency', res.data.currency);
            localStorage.setItem('symbol', res.data.currencySymbol);
            context.setloggedIn(localStorage.getItem('token'))
            navigate('/dashboard');
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          setLoading(false);
        });
    },
  });

  const gotoRegister = () => {
    navigate("/signup")
  }

  const ChangeToLowerCase = (e) => {
    formik.setFieldValue('email', e.target.value.toLowerCase())
  };


  // formik.handleChange()
  return (
    <>
      {loading ? <LoadingSpinner /> : null}
      <div className="container-fluid height_100per">
        <div className="row height_100per">
          <div className="accountLeftSide col-md-6 p0">
            {/* <div  style={{
          backgroundImage: `url(${AdminloginBG})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div> */}
            <img src={AdminloginBG} />
          </div>
          <div className="accountRightSide col-md-6 p0 WhiteFrame">
            <div className="accountPageInner margin_auto width_500">
              <div className="logo_brand">
                <img src={Mainlogo} alt="mainlogo" />
              </div>

              <h2 className="FrameHeading_28 textAlign_center">
                Welcome to <span className="hightLightThemeClr">SayShop</span>
              </h2>
              <p className="subDescription_text textAlign_center">
                Sign in to Continue
              </p>
              <div className="title_border"></div>
              <form onSubmit={formik.handleSubmit}>
                <div className="mb_24">
                  <label className="mb_8 inputTitle">Email address</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    className="MainInput"
                    onChange={(e) => { formik.handleChange(e); ChangeToLowerCase(e) }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <p className="error_text">{formik.errors.email}</p>
                  ) : null}
                </div>
                <div className="mb_30 ">
                  <label className="mb_8 inputTitle">Password</label>
                  <div className="inputGroup">
                    <input
                      type={type ? "password" : "text"}
                      id="password"
                      name="password"
                      placeholder="Your Password"
                      className="MainInput"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    <div className="inputGroup_append">
                      <span className="inputGroupText">
                        <img
                          src={type ? EyeshowIcon : EyehideIcon}
                          onClick={() => setType(!type)}
                          alt="eye_pic"
                        />
                      </span>
                    </div>
                  </div>

                  {formik.touched.password && formik.errors.password ? (
                    <p className="error_text">{formik.errors.password}</p>
                  ) : null}
                </div>
                <button
                  type="submit"
                  className="MainButton mb_24 width_160 margin_auto"
                >
                  Login
                </button>

                <p className="subDescription_text textAlign_center">
                  Do you want to Create a Business?
                  <a
                    onClick={gotoRegister}
                    className="cursorPointer textDecoration_unset ml_4"
                  >
                    Signup
                  </a>
                </p>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={1800} />
      </div>
    </>
  );
};

export default Login;
