import React, { useEffect, useState } from 'react'
import AdminNav from './AdminNav'
import AdminHeader from './AdminHeader'
import axios from 'axios'
import env from '../../enviroinment'
import { useNavigate } from 'react-router-dom'
import Pagination from '../Pagination'
import sampleTest from '../../assets/images/sample_test.jpg'
// import ViewIcon from '../../assets/images/ViewIconeye.svg';
import { array } from 'yup'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Modal from 'react-bootstrap/Modal'
import CloseIcon from '../../assets/images/close_icon.svg'
import PaidIcon from '../../assets/images/paid_icon.svg'
import ProcessIcon from '../../assets/images/process_icon.svg'
import PendingIcon from '../../assets/images/pending_icon.svg'
import DeliveredIcon from '../../assets/images/fastdelivered_icon.svg'
import OrderedIcon from '../../assets/images/ordered_icon.svg'
import OutdeliveryIcon from '../../assets/images/outfordelivery_icon.svg'
import NodataImage from '../../assets/images/noData.png'
import { useLocation } from 'react-router-dom'
import FilterIcon from "../../assets/images/filter_icon.svg";
import LoadingSpinner from '../loadingSpinner'



const Orders = () => {
  // New Reactbootstrap modal start

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  let [data, setData] = useState([])
  const [view, setView] = useState(false)
  const [product, setProduct] = useState([])
  const [status, setStatus] = useState([])
  const [item, setItem] = useState([])
  const [query, setQuery] = useState('')
  let navigate = useNavigate()
  const location = useLocation();
  let symbol = localStorage.getItem("symbol");

  //pagination states
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(10)
  let token = localStorage.getItem('token')
  let userId = localStorage.getItem('userId')
  let loadData = async () => {
    try {
      setLoading(true);
      let res = await axios.get(`${env.apiurl}/orders`, {
        headers: { Authorization: `Bearer ${token}`, adminid: userId },
      })
      setLoading(false);
      if (res.data.statusCode === 200) {
        setData(res.data.orders)
      } else {
        // navigate("/login");
      }
      return res.data.orders
    } catch (error) {
      setLoading(false);
    }

  }
  useEffect(() => {
    loadData()
  }, [])
  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)
    setView(false)
    window.scrollTo(0, 0)
  }
  //View Individual Product
  const handleShow = async (e, order) => {
    try {
      setShow(true)
      let orderId = order._id
      setView((current) => !current)
      setLoading(true);
      let res = await axios.get(`${env.apiurl}/orders/${orderId}`, {
        headers: { Authorization: `Bearer ${token}`, adminid: userId },
      })
      setLoading(false);
      if (res.data.statusCode === 200) {
        setItem(res.data.order.orderItems)
        let arr = []
        const {
          contactName,
          deliveryLocation,
          orderAmount,
          _id,
          orderStatus,
          paymenttype,
          paymentStatus,
          orderActivation,
        } = res.data.order
        let object = {
          contactName: contactName,
          deliveryLocation: deliveryLocation,
          orderAmount: orderAmount,
          orderStatus: orderStatus,
          paymenttype: paymenttype,
          paymentStatus: paymentStatus,
          orderActivation: orderActivation,
          _id: _id,
        }
        arr.push(object)
        setProduct(arr)
      } else {
      }
    } catch (error) {
      setLoading(false);

    }

  }
  // change order status
  const changeStatus = async (e, index, order_id) => {
    const newStatus = [...status]
    newStatus[index] = e.target.value
    setStatus(newStatus)
    e.preventDefault()
  }

  //change order status
  const changeorderStatus = async (e, order_id) => {
    try {
      e.preventDefault()
      setLoading(true);
      let res = await axios.put(`${env.apiurl}/orders/order-status/${order_id}`, '', {
        headers: { Authorization: `Bearer ${token}`, adminid: userId },
      })
      setLoading(false);

      if (res.data.statusCode === 200) {
        toast.success('Order Status Updated')
        loadData()
      } else {
      }
    } catch (error) {
      setLoading(false);
    }

  }
  //change payment status
  const changePayment = async (e, order_id) => {
    try {
      e.preventDefault()
      setLoading(true);
      let res = await axios.put(`${env.apiurl}/payments/paymentstatus/${order_id}`, '', {
        headers: { Authorization: `Bearer ${token}`, adminid: userId },
      })
      setLoading(false);
      if (res.data.statusCode === 200) {
        toast.success('Payment Status Updated')
        loadData()
      } else {
      }
    } catch (error) {
      setLoading(false);
    }

  }

  //close order
  const closeOrder = async (e, order_id) => {
    try {
      e.preventDefault()
      setLoading(true)
      let res = await axios.put(`${env.apiurl}/orders/closeorder/${order_id}`, '', {
        headers: { Authorization: `Bearer ${token}`, adminid: userId },
      })
      setLoading(false)

      if (res.data.statusCode === 200) {
        toast.success('Order Closed')
        loadData()
      } else {
      }
    } catch (error) {
      setLoading(false)

    }

  }
  // Get data for pagination
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage

  //Search Query Functionality
  const keys = ['contactName']
  // const searchQuery = (currentOrders) => {
  //   return currentOrders.filter((item) =>
  //     keys.some((key) => item[key].toLowercase().includes(query)),
  //   )
  // }
  let searchQuery = data.filter((products) =>
    products.contactName.toString().toLowerCase().includes(query.toLowerCase()) || products._id.toString().toLowerCase().includes(query)
  )
  searchQuery = searchQuery.slice(indexOfFirstPost, indexOfLastPost)

  const handleQueryinput = (event) => {

    if (location.pathname === "/order") {
      console.log("object", event.target.value);
      setQuery(event.target.value)
    }

  }

  const filterOrders = ({ target: { value: filterName } }) => {
    loadData().then((data) => {
      if (filterName === 'all') {
        return
      }

      if (filterName === 'last24hours') {
        const currentDate = new Date().getDate()
        const currentTime = new Date().getHours()

        const order = data.filter((order) => {
          const orderDate = new Date(order.createdAt).getDate()
          const orderTime = new Date(order.createdAt).getHours()

          if (orderDate === currentDate) {
            return order
          }

          if (orderDate === currentDate - 1 && orderTime - currentTime > 0) {
            console.log(orderTime, currentTime)
            return order
          }
        })
        setData(order)
        return
      }

      const order = data.filter((order) => order.orderActivation === filterName)
      setData(order)
    })

  }
  let currencySymbol = localStorage.getItem('currencySymbol')
  return (
    <>
      {loading ? <LoadingSpinner /> : null}
      <div>
        <div className="Main_header">
          <AdminHeader onChange={(e) => handleQueryinput(e)} />
          <AdminNav />
        </div>

        <div className="container">
          <div className="pageInfo">
            <div>
              <h2 className="heading24_bold">Orders</h2>
            </div>
            <div className="select_dropdown_icon">
              <div className="input-group-prepend">
                <span className="inputGroup_text">
                  <img src={FilterIcon} />
                </span>
              </div>
              <select defaultValue={'default'} onChange={(event) => filterOrders(event)}>
                <option value={'default'} disabled >Filter</option>
                <option value={'all'}>All</option>
                <option value={'Open'}>Open</option>
                <option value={'Close'}>Closed</option>
                <option value={'last24hours'}>Last 24 hours</option>
              </select>
            </div>
          </div>
          <div className="TableWrapper tableResponsive">
            <table>
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>Total</th>
                  <th>Payment Mode</th>
                  <th>Payment Status</th>
                  <th>Change Payment Status</th>
                  <th>Status</th>
                  <th>Edit Order Status</th>
                  <th>Order Activation</th>
                </tr>
              </thead>
              <tbody>
                {searchQuery.length > 0
                  ? searchQuery.map((order, index) => {
                    return (
                      <tr
                        key={index}
                      // style={
                      //   order.orderActivation === 'Close'
                      //     ? { textDecoration: 'line-through' }
                      //     : null
                      // }
                      >
                        <td>{order.contactName}</td>
                        <td>{symbol} {order.orderAmount}</td>
                        {/* <td>
                      <select
                        className="smallDropdown"
                        value={order.orderStatus}
                        onChange={(e) => changeStatus(e, order._id)}
                        onClick={(e) => changeorderStatus(e, order._id)}
                      >
                        <option>Ordered </option>
                        <option>Out for delivery </option>
                        <option>Delivered</option>
                        <option>Cancel Order</option>
                      </select>
                    </td> */}
                        <td>
                          <span
                            className={
                              order.paymenttype === "cashonDelivery"
                                ? "cashtextClr"
                                : "onlinetextClr"
                            }
                          >
                            {order.paymenttype === "cashonDelivery"
                              ? "Cash"
                              : "Online"}
                          </span>
                        </td>

                        <td>
                          {order.paymentStatus === "Processed" ? (
                            <div className="displayFlex alignItem_Center">
                              <img className="mr_4" src={ProcessIcon} />
                              <span className="processTextClr">
                                {order.paymentStatus}
                              </span>
                            </div>
                          ) : order.paymentStatus === "Pending" ? (
                            <div className="pendingText displayFlex alignItem_Center">
                              <img className="mr_4" src={PendingIcon} />
                              <span className="pendingTextClr">
                                {order.paymentStatus}
                              </span>
                            </div>
                          ) : (
                            <div className="paidTextClr displayFlex alignItem_Center">
                              <img className="mr_4" src={PaidIcon} />
                              <span className="paidTextClr">
                                {order.paymentStatus}
                              </span>
                            </div>
                          )}
                        </td>
                        <td>
                          <button
                            className="primarySmallBtn"
                            onClick={(e) => changePayment(e, order._id)}
                          >
                            Change
                          </button>
                        </td>

                        <td>
                          {order.orderStatus === "Ordered" ? (
                            <div className="displayFlex alignItem_Center">
                              <img className="mr_4" src={OrderedIcon} />
                              <span className="orderTextClr">
                                {order.orderStatus}
                              </span>
                            </div>
                          ) : order.orderStatus === "Out for delivery" ? (
                            <div c>
                              <img className="mr_4" src={OutdeliveryIcon} />
                              <span className="outofdeliveryTextClr">
                                {order.orderStatus}
                              </span>
                            </div>
                          ) : (
                            <div>
                              <img className="mr_4" src={DeliveredIcon} />
                              <span className="delivereTextClr">
                                {order.orderStatus}
                              </span>
                            </div>
                          )}
                        </td>
                        <td>
                          <button
                            className="smallHoverButton mr_12"
                            onClick={(e) => handleShow(e, order)}
                          >
                            View
                          </button>
                          {order.orderStatus !== "Delivered" ? (
                            <button
                              className="primarySmallBtn mr_12"
                              onClick={(e) => changeorderStatus(e, order._id)}
                            >
                              Next
                            </button>
                          ) : null}
                          {order.orderStatus == "Delivered" &&
                            order.paymentStatus == "Paid" ? (
                            <button
                              className="primarySmallBtn mr_12"
                              onClick={(e) => closeOrder(e, order._id)}
                            // style={
                            //   order.orderActivation === 'Close'
                            //     ? { textDecoration: 'line-through' }
                            //     : null
                            // }
                            >
                              Close
                            </button>
                          ) : null}
                        </td>
                        <td>
                          <span
                            className={
                              order.orderActivation === "Open"
                                ? "Badge BadgeSoftsuccess"
                                : "Badge BadgeSoftDanger"
                            }
                          >
                            {order.orderActivation}
                          </span>
                        </td>
                      </tr>
                    );
                  })
                  : data.length > 0 && (
                    <tr>
                      <td colSpan="6" className="textAlign_center">
                        <img src={NodataImage} />
                        <h3 className="nodataTitle">
                          Sorry! No result found :(
                        </h3>
                        <p className="subDescription_text">
                          Sorry we couldn't find any matches for the product
                        </p>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
            <Modal show={show} onHide={handleClose} className="addnewpopup">
              <Modal.Header>
                <div>
                  <h4>Order Details</h4>
                </div>
                <div>
                  <button className="emptyBtn" onClick={handleClose}>
                    <img src={CloseIcon} />
                  </button>
                </div>
              </Modal.Header>
              <div className="modelScroll">
                <Modal.Body>
                  {view && (
                    // <tr className="OrderList_table">
                    //   <td colSpan="9" className="p0">
                    <div className="OrderList_table">
                      <table>
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Product</th>
                            <th>Quantity</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item?.map((items, index) => (
                            <tr key={index}>
                              <td>
                                <div className="OrderImg">
                                  <img
                                    className="userImg mr_12"
                                    src={items.image ? items.image : null}
                                    alt=""
                                  />
                                </div>
                              </td>
                              <td>{items.name}</td>
                              <td>{items.selected_quantity}</td>
                              <td>{currencySymbol}{items.selected_quantity_price}</td>
                            </tr>
                          ))}
                          {product
                            ? product.map((order, index) => {
                              return (
                                <tr className="orderlist_detail" key={index}>
                                  <td>
                                    <h5>Order ID</h5>
                                    <p>{order._id}</p>
                                  </td>
                                  <td>
                                    <h5>Customer</h5>
                                    <p>{order.contactName}</p>
                                  </td>
                                  <td>
                                    <h5>Delivery location</h5>
                                    <p>{order.deliveryLocation}</p>
                                  </td>
                                  <td>
                                    <h5>Total Amount</h5>
                                    <p>{order.orderAmount}</p>
                                  </td>
                                </tr>
                              );
                            })
                            : null}
                        </tbody>
                      </table>
                    </div>
                    //   </td>
                    // </tr>
                  )}
                </Modal.Body>
              </div>
            </Modal>
          </div>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={data.length}
            paginate={paginate}
          />
        </div>
        <ToastContainer autoClose={1800} />
      </div>
    </>
  );
}

export default Orders
