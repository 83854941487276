import { env_variables } from './env'

import httpHandler from './httpHandler'



export const AuthPost = async (url, body) => {

  let token = localStorage.getItem('token')
  let userId = localStorage.getItem('userId')
  let optionalHeaders = {
    Authorization: token ? `Bearer ${token}` : '',
    adminid: userId ? userId : ''
  }
  let activeUrl = `${env_variables.apiurl}/${url}`
  let data = await httpHandler.post(activeUrl, body, optionalHeaders)
  return data
}

export const AuthGet = async (url) => {


  let token = localStorage.getItem('token')
  let userId = localStorage.getItem('userId')
  let optionalHeaders = {
    Authorization: token ? `Bearer ${token}` : '',
    adminid: userId ? userId : ''
  }
  let activeUrl = `${env_variables.apiurl}/${url}`
  let data = await httpHandler.get(activeUrl, '', optionalHeaders)
  return data
}

export const Get = async (url) => {
  let activeUrl = `${env_variables.apiurl}/${url}`
  let data = await httpHandler.get(activeUrl, '', {})
  return data
}

export const Post = async (url, body) => {


  let activeUrl = `${env_variables.apiurl}/${url}`
  console.log(activeUrl, body)
  let data = await httpHandler.post(activeUrl, body, {})
  console.log(data)
  return data
}

export const Put = async (url, body) => {
  let activeUrl = `${env_variables.apiurl}/${url}`
  let data = await httpHandler.put(activeUrl, body, {})
  return data
}

export const AuthPut = async (url, body) => {

  let token = localStorage.getItem('token')
  let userId = localStorage.getItem('userId')
  let optionalHeaders = {
    Authorization: token ? `Bearer ${token}` : '',
    adminid: userId ? userId : ''
  }
  let activeUrl = `${env_variables.apiurl}/${url}`
  let data = await httpHandler.put(activeUrl, body, optionalHeaders)
  return data
}

export const AuthFile = async (url, body, method = 'post') => {



  let token = localStorage.getItem('token')
  let userId = localStorage.getItem('userId')

  let activeUrl = `${env_variables.apiurl}/${url}`
  if (method === 'post') {
    let data = await httpHandler.post(activeUrl, body, {
      'content-type': 'multipart/form-data',
      Authorization: token ? `Bearer ${token}` : '',
      adminid: userId ? userId : ''
    })
    return data
  } else if (method === 'patch') {
    let data = await httpHandler.patch(activeUrl, body, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    return data
  }
}


// export const AuthDownloadblob = async (url, key) => {
//   let token
//   if (key == 'admin') {
//     token = localStorage.getItem('admin_token')
//   } else if (key == 'borrower') {
//     token = localStorage.getItem('borrower_token')
//   }else if (key == 'dealer') {
//     token = localStorage.getItem('dealer_token')
//   }

//   let optionalHeaders = {
//     Authorization: token ? `Bearer ${token}` : '',
//     responseType: 'blob',
//   }

//   let activeUrl = geturl(url, key)
//     let data = await httpHandler.get(activeUrl,'',optionalHeaders)
//     return data
// }